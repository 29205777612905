






































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue, Prop } from "vue-property-decorator";
import { ITableItem } from "@/modules/budget-request/components/js/budgetFinalResult.ts";
@Component({
    name: 'c-table-budget-fr',
    components: {
    }
})
export default class CTableBudgetFr extends Vue {

    @Prop({
        required: true,
        default: {}
    })
    private filter!: any;

    @Prop({
        required: true,
        default: null
    })
    private finalResultTableData!: any;

    // @Prop({
    //     required: true,
    //     default: []
    // })
    // private dictForecast!: any;

    private created() {
        this.$watch('finalResultTableData', this.prepareTableData, { deep: true });
    }

    // подготовка данных для таблицы
    private tableData: any[] = [];
    private prepareTableData() {
        this.tableData = [];
        if (!this.finalResultTableData) { return; }

        if (this.finalResultTableData.finalResults && this.finalResultTableData.goal && this.finalResultTableData.finalResults.length > 0) {
            this.finalResultTableData.finalResults.forEach((fr: any, i: number) => {
                const targetAmount = []
                if (fr.forecastYears && fr.forecastYears.length > 0) {
                    for (let y = 0; y < 3; y++) {
                        const year = this.filter.year + y;
                        const val = fr.forecastYears.filter((item: any) => item.year === year);
                        if (val.length > 0) {
                            targetAmount.push(val[0].plan);
                        } else {
                            targetAmount.push(null);
                        }
                    }
                }
                const tableRow: ITableItem = {
                    rowNumber: i + 1,
                    name_en: fr.finalResult.name_en,
                    name_kk: fr.finalResult.name_kz,
                    name_ru: fr.finalResult.name_ru,
                    unit_ru: fr.unit ? fr.unit.nameRu : '',
                    unit_kk: fr.unit ? fr.unit.nameKk : '',
                    unit_en: fr.unit ? fr.unit.nameRu : '',
                    target1: targetAmount[0],
                    target2: targetAmount[1],
                    target3: targetAmount[2],
                    plan1: null,
                    plan2: null,
                    plan3: null,
                    goalCode: this.finalResultTableData.goal.code,
                    indCode: fr.finalResult.code
                };
                this.tableData.push(tableRow);
            });
        }
    }


    public setNameLang(obj: any, text: string) {
        let txt = obj[text + '_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        return txt;
    }

    // public getPprName(ppr: number): string {
    //     const pprItem = this.commonResources.pprList.find((item: any) => {
    //         return item.ppr === ppr;
    //     });
    //     if (pprItem) {
    //         return pprItem.name_ru;
    //     }
    //     return ppr + "";
    // }

    // public formatCurrency(currency: number | null | undefined): string {
    //     if (currency || currency === 0.0) {
    //         return currency.toLocaleString();
    //     }
    //     return '';
    // }

    // public zeroPad(num: number, places: number): string {
    //     const zero = places - num.toString().length + 1;
    //     return Array(+(zero > 0 && zero)).join("0") + num;
    // }

    // private makeToast(variant: any, title: string, tostbody: any) {
    //     this.$bvToast.toast(tostbody, {
    //         title: title,
    //         variant: variant,
    //         toaster: 'b-toaster-top-center',
    //         autoHideDelay: 5000,
    //         appendToast: true
    //     });
    // }
}
