






































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';
import {Ax} from "@/utils";
import { BrbpDri } from '@/modules/budget-request/components/js/dri';
import { BrbpSee } from '@/modules/budget-request/components/js/see';
import { BrbpPee } from '@/modules/budget-request/components/js/pee';
import { Brbp } from '@/modules/budget-request/components/js/brbp';
import {
    BrbpPprWrapper,
    BrbpWrapper,
    IBrbpWrapper
} from '@/modules/budget-request/components/js/brbp-wrapper';
import CTableBudgetExpend from '@/modules/budget-request/components/table-budget-expend.vue';
import CTableDriEdit from '@/modules/budget-request/components/table-dri-edit.vue';
import CTableBudgetFr from '@/modules/budget-request/components/table-budget-fr.vue';
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import CTableBudgetEffect from '@/modules/budget-request/components/table-budget-effect.vue';
import { CUR_YEAR } from './components/js/budgetCurYear';
import CBreadCrumbs from './components/table-dri-breadcrumbs.vue';
import CActionModal from './components/action-modal.vue';

const PRG_CURR = 0; // ID текущей программы
const PRG_DEV = 1; // ID программы развития
@Component({
    name: 'c-budg-program',
    components: { 'c-table-budget-expend': CTableBudgetExpend,
        'c-table-dri-edit': CTableDriEdit,
        'c-budg-form-lst': CBudgetFormsList,
        'c-breadcrumbs': CBreadCrumbs,
        'c-table-budget-fr': CTableBudgetFr,
        'c-table-budget-effect': CTableBudgetEffect,
        'c-action-modal': CActionModal }
})

export default class CBudgProgram extends Vue {

    @Prop({
        required: false,
        default: false
    })
    private bpMode!: boolean;

    private testSwitch = true;

    private switchPages(action = 'enterEditMode') {
        if (action === 'exitEditMode') {
            this.isUserEditMode = false;
            // this.aggHistory.splice(0);
            this.$emit('setBpInEditMode', false);
        }
        this.showTop = false;
        this.testSwitch = !this.testSwitch;
        this.$root.$emit('removeClass');
    }
    private progress = 0;
    private tableFieldsTest = [
        {
            key: 'collapse',
            label: ' ',
            class: 'toggle-show'
        },
        {
            key: 'gr',
            label: 'Код'
        },
        {
            key: 'name_ru',
            label: 'Функциональная группа'
        },
        {
            key: 'abp',
            label: 'АБП'
        },
        {
            key: 'prg',
            label: 'ПРОГРАММА'
        },
        {
            key: 'name_ru1',
            label: 'НАИМЕНОВАНИЕ'
        },
        {
            key: 'action',
            label: ''
        }

    ];
    private tableFieldsItems: any = [];
    private filter: any = [];
    private curFormSelect = '/budget-program';
    private brbpWrapper: BrbpWrapper = new BrbpWrapper();// данные БЗБП
    private brbpPprSelected: IBrbpWrapper = new BrbpPprWrapper(undefined, [], [], []);
    private dataChanged = false;
    private isUserEditMode = false;

    private commonResources: any = {
        pprList: []
    };

    private curYear: any = [];
    private get years(): any[] {
        const year = CUR_YEAR + 1;
        const periodLst = [];
        const startDate = 2019;
        const endDate = year + 2;
        for (let i = endDate; i >= startDate; i--) {
            periodLst.push({ name: `${i} - ${i + 2}`, year: i });
            if (year === i) {
                this.curYear = { name: `${i} - ${i + 2}`, year: i };
            }
        }
        return periodLst;
    }

    private driTopOffset: string | null = null;
    private costTopOffset: string | null = null;
    private effTopOffset: string | null = null;

    private setOffset() {
        const dri: HTMLElement | null = document.getElementById('dri-thead');
        const cost: HTMLElement | null = document.getElementById('cost-thead');
        const eff: HTMLElement | null = document.getElementById('eff-thead');
        
        if (dri && cost && eff) {
            setTimeout(() => {
                this.driTopOffset = window.getComputedStyle(dri).height;
                this.costTopOffset = window.getComputedStyle(cost).height;
                this.effTopOffset = window.getComputedStyle(eff).height;
            }, 1000);
        }
    }


    // --------------------

    private budgetLevel: number[] = [];

    private get usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }
    // ------- regions
    private regionBase: any[] = [];
    private curRegion: any | null = null;
    private regions: any[] = [];
    private region() {
        const res = [];
        for (const el of this.regionBase) {
            res.push(this.setNameLang(el, 'code'));
        }
        res.sort((a, b) => (a.code - b.code > 0) ? 1 : -1);
        if (res.length > 0) { this.curRegion = res[0]; }
        this.uploadData();
        this.regions = res;
    }

    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) { this.curAbp = this.setNameLang(this.curAbp, 'abp'); }
        return res;
    }

    private chgRegion() {
        this.uploadData();
    }

    private chgYear() {
        this.uploadData();
    }

    private chgAbp() {
        this.uploadData();
    }

    // =======Обновление целей БП и КР=========
    private async refreshGoals() {
        if (!this.addProgamMode) {
            await this.loadBpGoalFrs();
        }
        // проверка изменилась ли цель и КР выбранные в данный момент. Если изменились тогда Выбранная цель сбрасывается.
        if (this.curBpGoal && this.curBpGoalFrs.length > 0) {
            this.goal = Number(this.curBpGoal.id);
            const curGoal = this.curBpGoalFrs.filter((item: any) => item.goal.id === this.curBpGoal.id);
            const newGoal = this.newGoalsAndFr.filter((item: any) => item.goal.id === this.curBpGoal.id);
            if (curGoal.length === 1 && newGoal.length === 1) {
                const curFrs: any[] = curGoal[0].finalResults;
                const newFrs: any[] = newGoal[0]['finalResults'];
                if (curFrs.length !== newFrs.length) {
                    this.goal = null;
                } else {
                    curFrs.forEach((curFr: any) => {
                        if (newFrs.findIndex((newFr: any) => newFr.finalResult.code === curFr.finalResult.code) === -1) {
                            this.goal = null;
                        }
                    });
                }
            }
        }
        if (this.newGoalsAndFr.length === 0) {
            if (!this.addProgamMode) {
                this.makeToast('warning', 'Предупреждение', 'Новые цели БП и КР не найдены');
            }
        } else {
            if (this.brbpWrapper) {
                if (!this.brbpWrapper.brbp) {
                    this.brbpWrapper.brbp = new Brbp(
                            this.curYear.year,
                            this.curRegion.code,
                            this.curAbp.abp,
                            this.curProg.prg,
                            null
                        );
                }
                this.brbpWrapper.brbp!.textGoalFr = { goalFinalResults: this.newGoalsAndFr };
                this.parseGoals(true);
                this.makeToast('success', 'Обновление', 'Цели БП и КР обновлены');
            }
        }
    }

    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private chgProg() {
        this.setFilter();
        this.loadData();
    }
    // ---------- подпрограмма
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        return res;
    }

    private chgSubProg() {
        this.setFilter();
        this.afterChangePpr();
        this.setOffset();
    }
    // Проекты и ППР
    private dictProjects: any[] = [];
    private dictDris: any[] = [];
    private dictSee: any[] = [];
    private dictUnits: any[] = [];

    // ----------- нормативно правовая основа
    private regulatoryBase: any[] = [];
    private curRegulatory: any[] = [];
    private curRegulatoryList: any[] = [];
    private regulatory() {
        this.searchNPA = '';
        const result: any[] = [];
        for (const el of this.regulatoryBase) {
            const newEl = Object.assign({}, el);
            result.push({ text: newEl.name_ru, value: newEl.id, filtered: false, selected: this.curRegulatory.includes(newEl.id) });
        }

        this.curRegulatoryList = result.sort((x, y) => { return (x.selected === y.selected)? 0 : x.selected ? -1 : 1; });
    }

    // получение списка бюджетных программ для модалки
    private curBP: number | null = null;
    private bpList: any[] | null = [];
    private onOpenBPModal() {
        this.searchBP = '';
        this.curProg = null;
        this.curBP = null;
        this.curBpGoal = null;
        this.relativeFR = null;
        this.getBPList();
    }
    private getBPList(searchLine: string | null = null) {
        const result: any[] = [];
        for (const el of this.progBase) {
            const newEl = Object.assign({}, el);
            const text = `${newEl.prg} - ${newEl.name_ru}`;
            if (searchLine) {
                if (text.toLowerCase().indexOf(searchLine.toLowerCase()) !== -1) {
                    result.push({ text, prg: newEl.prg, value: newEl.id });
                }
            } else {
                result.push({ text, prg: newEl.prg, value: newEl.id });
            }
        }
        result.sort((a: any, b: any) => a.prg - b.prg);
        const newResult: any[] = [];
        result.forEach((row: any) => {
            if (this.tableFieldsItems.findIndex((elem: any) => elem.id === row.value) === -1) {
                newResult.push(row);
            }
        });
        this.bpList = newResult;
    }
    private curPrgId: number | null = null;
    private curPrg() {
        if (this.bpList) {
            this.curPrgId = this.bpList.filter((item: any) => item.value === this.curBP)[0].prg;
        }
    }
    // добавление новой программы
    private addProgamMode = false;
    private onBPModalOk(e: any) {
        this.brbpCode = null;
        this.aggHistory.splice(0);
        this.goal = null;
        if (!this.curBP) {
            e.preventDefault();
            this.makeToast('warning', 'Предупреждение', 'Необходимо выбрать программу');
        } else {
            this.addProgamMode = true;
            const currProgItem = this.progBase.filter(prg => prg.id === this.curBP)[0];
            this.curProg = currProgItem;
            this.chgProg();
            this.switchPages();
            this.curPrg();
        }
    }

    // =========Удаление БП==========
    private bpId: number | null = null;
    private setBpId(bpId: number) {
        this.bpId = bpId;
    }
    private async onDelBP() {
        try {
            const response = await fetch(`api/budget-programs/brbp/${this.bpId}`, {
                method: 'DELETE'
            });
            if (response.status === 200) {
                const delId = this.tableFieldsItems.findIndex((elem: any) => elem.bpId === this.bpId);
                this.tableFieldsItems.splice(delId, 1);
                this.makeToast('success', 'Удаление', 'Бюджетная программа удалена');
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка удаления БП', error.toString());
        }
    }

    // поиск по списку бюджетных программ в модалке
    private searchBP = '';

    private onSearchBP() {
        this.getBPList(this.searchBP);
    }

    // поиск по НПА
    private searchNPA = '';

    private onSearchNPA() {
        this.curRegulatoryList = this.curRegulatoryList.map((item: any) => {
            if (item.text.toLowerCase().indexOf(this.searchNPA.toLowerCase()) !== -1) {
                return { ...item, filtered: false };
            }
            return { ...item, filtered: true };
        });
    }

    // --------- Цель БП
    private curBpGoal: any = null;
    private bpGoalsList: any[] = [];
    private goal: number | null = null;
    private curBpGoalFrs: any[] = [];
    private relativeFR: any = null;
    private getFinalResultName(finalResultRec: any): string {
        let res = finalResultRec.finalResult.name_ru;
        const arr: any[] = finalResultRec.forecastYears;
        arr.sort((a: any, b: any) => (a.year > b.year) ? 1 : ((b.year > a.year) ? -1 : 0));
        for (const forecastYear of arr) {
            res = res + ` ${forecastYear.year} г. - ${forecastYear.plan};`;
        }
        return res;
    }
    private finalResultTableData = null;
    private updateFR(changed = false) {
        this.curFinalResults.splice(0);
        this.finalResultTableData = this.curBpGoalFrs[this.curBpGoal.recId];
        this.curBpGoalFrs.forEach(goalFr => {
            goalFr.finalResults.forEach((fr: any, i: number) => {
                this.curFinalResults.push({ ...fr.finalResult, name: `${fr.finalResult.code} - ${fr.finalResult.name_ru}` });
            });
        })
    }

    // очистка конечного результата в ППР
    private ifFrsShouldBeCleared = false;
    // private clearFrs() {
    //     if (this.brbpWrapper.pprData && this.brbpWrapper.pprData.length > 0) {
    //         this.brbpWrapper.pprData.forEach((ppr: any) => {
    //             if (ppr.driData && ppr.driData.length > 0) {
    //                 ppr.driData.forEach((dri: any) => {
    //                     if (this.curFinalResults.length > 1) {
    //                         dri.finalResult = '';
    //                     } else {
    //                         dri.finalResult = this.curFinalResults[0].code;
    //                     }
    //                 });
    //                 this.ifFrsShouldBeCleared = !this.ifFrsShouldBeCleared;
    //             }
    //         });
    //     }
    //     this.filter.curBpGoal = this.curBpGoal;
    // }

    // Парсинг целей БП
    private parseGoals(refresh = false) {
        this.bpGoalsList.length = 0;
        this.curBpGoal = null;
        this.relativeFR = null;
        this.finalResultTableData = null;
        if (this.brbpWrapper && this.brbpWrapper!.hasOwnProperty('brbp') && this.brbpWrapper.brbp!.textGoalFr) {
            const bpGoals = this.brbpWrapper.brbp!.textGoalFr!.goalFinalResults;
            this.curBpGoalFrs = JSON.parse(JSON.stringify(bpGoals));
            bpGoals.forEach((item: any, i: number) => {
                const newGoalItem = this.setNameLang(item.goal);
                this.bpGoalsList.push({ name: newGoalItem.name, id: newGoalItem.id, recId: i, goal: newGoalItem.code });
            });
            if (this.goal) {
                this.curBpGoal = this.bpGoalsList.filter((item: any) => {
                    return Number(item.id) === this.goal;
                })[0];
                if (this.curBpGoal) this.updateFR();
            }
        }
        if (!refresh) {
            this.checkIfGoalsAndFrActual();
        }
    }
    private newGoalsAndFr = [];
    private async loadBpGoalFrs() {
        if (!this.curYear || !this.curAbp || !this.curProg) {
            return;
        }
        const regionArg = this.curRegion ? `&region=${this.curRegion.code}` : '';
        try {
            const response = await fetch(`api/budget-programs/bp_frs?year=${this.curYear.year}&abp=${this.curAbp.abp}&prg=${this.curProg.prg}${regionArg}`);
            if (response.status === 200) {
                this.newGoalsAndFr = await response.json();
                this.showTop = false;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка получения новых целей БП и КР', error.toString());
        }
    }

    // проверка актуальности целей БП и КР
    private showTop = false;
    private async checkIfGoalsAndFrActual() {
        if (this.bpMode) { return; }
        await this.loadBpGoalFrs();
        let goalsOrFrsChanged = false;
        if (this.newGoalsAndFr.length > 0 || this.curBpGoalFrs.length > 0) {
            if (this.newGoalsAndFr.length !== this.curBpGoalFrs.length) {
                goalsOrFrsChanged = true;
            }
            if (!goalsOrFrsChanged) {
                for (const newGoal of this.newGoalsAndFr) {
                    const index = this.curBpGoalFrs.findIndex((item: any) => newGoal['goal']['code'] === item.goal.code);
                    if (index === -1) {
                        goalsOrFrsChanged = true;
                        break;
                    } else {
                        const currGoal = this.curBpGoalFrs[index];
                        if (newGoal['finalResults']['length'] !== currGoal.finalResults.length) {
                            goalsOrFrsChanged = true;
                            break;
                        } else {
                            const newGoalFR: any[] = newGoal['finalResults'];
                            for (const newRes of newGoalFR) {
                                if (currGoal.finalResults.findIndex((curRes: any) => curRes.finalResult.code === newRes.finalResult.code) === -1) {
                                    goalsOrFrsChanged = true;
                                    break;
                                }

                                const newResCode = newRes.finalResult.code;
                                const currGoalRes = currGoal.finalResults.filter((fr: any) => fr.finalResult.code === newResCode)[0];
                                if (newRes.forecastYears.length !== currGoalRes.forecastYears.length) {
                                    goalsOrFrsChanged = true;
                                    break;
                                } else {
                                    for (let i = 0; i <= 2; i++) {
                                        const year = this.curYear.year + i;
                                        const a = newRes.forecastYears.filter((fry: any) => fry.year === year)[0];
                                        const b = currGoalRes.forecastYears.filter((fry: any) => fry.year === year)[0];
                                        if ((!a && b) || (a && !b)) {
                                            goalsOrFrsChanged = true;
                                            break;
                                        } else if (a && b && a.plan !== b.plan) {
                                            goalsOrFrsChanged = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (this.addProgamMode) {
            await this.refreshGoals();
            this.addProgamMode = false;
        } else if (this.editMode) {
            this.showTop = goalsOrFrsChanged;
            this.editMode = false;
            this.$emit('setBpInEditMode', false);
        }
    }

    // --------- в зависимости от уровня гос управления
    private govLevelBase: any[] = [];
    private curGovLevel: any | null = null;

    private get govLevel() {
        const result: any[] = [];
        for (const el of this.govLevelBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        return result;
    }
    // -------------в зависимости от способа реализации
    private implementWayBase: any[] = [];
    private curImplementWay: any | null = null;

    private get implementWay() {
        const result: any[] = [];
        for (const el of this.implementWayBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        return result;
    }
    // ------------ в зависимости от содержания
    private contentDictBase: any[] = [];
    private curContentDict: any | null = null;

    private get contentDict() {
        const result: any[] = [];
        for (const el of this.contentDictBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        return result;
    }

    // ---------- текущая/развитие
    private bpStateBase: any[] = [];
    private get curBpState(): number {
        return (this.curProg && this.curProg.developType === PRG_DEV) ? PRG_DEV : PRG_CURR;
    }

    private set curBpState(value: number) {}

    private get isPrgDev(): boolean {
        return this.curBpState === PRG_DEV;
    }

    private get bpState() {
        const result: any[] = [];
        for (const el of this.bpStateBase) {
            result.push(this.setNameLang(el));
        }
        return result;
    }

    // ----------- список целей
    private goalsBase: any[] = [];
    private curGoals: any | null = null;

    private get goals() {
        const result: any[] = [];
        for (const el of this.goalsBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        return result;
    }

    // --------------- конечные результаты
    private finalResultBase: any[] = [];
    private curFinalResults: any[] = [];

    private get finalResult() {
        const result: any[] = [];
        for (const el of this.finalResultBase) {
            result.push(this.setNameLang(el));
        }
        return result;
    }

    // ------------Описание (обоснование) БП:
    private brbpDescr = { descrRu: '', descrKK: '' };

    // --------------описание (обоснование) БПП
    private bppDescr = { descrRu: '', descrKK: '' };

    // ---------------Описание влияния заявленного финансирования на достижение показателей результатов бюджетных программ
    private descrInfl: any = []; // программ
    private subDescrInfl: any = [{ descrRu: '', descrKk: '' }, { descrRu: '', descrKk: '' }, { descrRu: '', descrKk: '' }]; // подпрограмм
    // --------------- справочник показателей
    private indicDictBase: any[] = [];
    private curIndicDict: any | null = null;

    private get indicDict() {
        const result: any[] = [];
        for (const el of this.indicDictBase) {
            result.push(this.setNameLang(el));
        }
        if (result.length > 0 && this.curIndicDict === null) { this.curIndicDict = result[0]; }
        return result;
    }

    // -------------- load --------------------
    private async loadBudgetLevel() { // загрузка уровня бюджета
        this.budgetLevel = []; 
        if (this.curRegion) {
            if (this.curRegion.code.slice(this.curRegion.code.length - 4) === '0101') {
                this.budgetLevel.push(2);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) === '01')  {
                this.budgetLevel.push(3);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) !== '00') {
                this.budgetLevel.push(4);
            }
        }
        this.loadAbp();
        this.getAvlblGrps();
        this.getAccessData();
        // this.budgetLevel = [];
        // if (this.usrId === null) { return; }
        // let result = [];
        // try {
        //     result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
        //     console.log('result', result)
        // } catch (error) {
        //     this.makeToast('danger', 'Ошибка загрузки уровня бюджета', error.toString());
        //     return;
        // }

        // for (const el of result) {
        //     const indx = el.budget_level.lastIndexOf('_');
        //     if (indx >= 0) {
        //         this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
        //         console.log('this.budgetLevel', this.budgetLevel)
        //     }
        // }
        // if (this.budgetLevel.length === 0) { this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!'); }
    }

    private async loadAbp() { // загрузка АБП
        let response: any = [];
        this.abpBase = [];
        if (!this.budgetLevel || this.budgetLevel.length < 1 || !this.usrId) return; 
        
        try {
            response = await fetch(`/api-py/get-dict-ved-abp-by-budgetlevel-user/${encodeURI(JSON.stringify(this.budgetLevel))}/${this.usrId}`);
            if (response.status === 200) {
                response = await response.json();
            } else {
                this.makeToast('danger', 'Ошибка загрузки адм. программ', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки адм. программ', error.toString());
        }

        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
        this.chgAbp();
        this.progress = 10;
    }

    // управление правами доступа
    private modulecode: String = this.bpMode ? '004.003.006' : '004.002.008';
    private actionsBtnDsbled: Boolean = true;
    private onlyReadMode = true;

    private async getAccessData() { // получение разрешенных доступов
        let response: any = {};
        if (!this.usrId) return; 
         try {
            response = await fetch(`api/um/module/link?user=${this.usrId}&modulecode=${this.modulecode}`);
            if (response.status === 200) {
                response = await response.json();
                response.operations = ['uebp_agree', 'abp_agree']
                if (response.operations && response.operations.length) {
                    if (this.bpMode) {
                        if (response.operations.includes('uebp_agree')) this.actionsBtnDsbled = false;
                    } else {
                        if (response.operations.includes('abp_agree') || response.operations.includes('abp_sign')) this.actionsBtnDsbled = false;
                    }
                }
                if (response.accessLevel && (response.accessLevel === 2 || response.accessLevel === 3)) {
                    this.onlyReadMode = false;
                }

            } else {
                this.makeToast('danger', 'Ошибка загрузки уровней доступа', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            
            this.makeToast('danger', 'Ошибка загрузки уровней доступа', error.toString());
        }
    }

    private async loadProg() { // загрузка программ
        let response: any = [];
        this.progBase = [];
        if (this.curAbp !== null) {
            try {
                response = await fetch(`/api/budget-programs/ebk_func/prg?abp=${this.curAbp.abp}`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', error.toString());
            }
        }

        response = response
            .filter((prg: any) => { return !!this.avlblGrpsList.find((ag: any) => ag.fgr === prg.gr); })
            .sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
        this.progBase = this.setIdArr(response, 'prg');
    }

    private async loadSubProg() { // загрузка справочника подпрограмм
        this.subProgBase = [];
        let response: any = [];
        if (this.curAbp !== null) {
            try {
                response = await fetch(`/api-py/get-dict-func-childs-by-parent-type/${this.curProg.id}/5`);
                if (response.status === 200) {
                    response = await response.json();
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', error.toString());
            }
        }
        this.subProgBase = response;
        this.commonResources.pprList = response;
        if (this.subProgBase.length > 0) { 
            this.curSubProg = this.setNameLang(this.subProgBase[0], 'ppr'); 
        } else {
            this.curSubProg = null;
        }
        this.chgSubProg();
    }
    private progress2 = 0;
    private async loadData(preload = false) {
        this.conclusions = {
            conclNpaRu: null,
            conclDescrRu: null,
            conclFrRu: null,
            conclExpendRu: null,
            conclDriRu: null,
            conclNpaCode: null,
            conclDescrCode: null,
            conclFrCode: null,
            conclExpendCode: null
        };
        this.selectedTable = 'ppr';
        this.newGoalsAndFr.length = 0;
        this.isDriDataReady = false;
        this.bpGoalsList.length = 0;
        this.curRegulatory.length = 0;
        this.isUserEditMode = false;
        let response: any = null;
        this.curGovLevel = null;
        this.curContentDict = null;
        this.curImplementWay = null;
        this.curGoals = null;
        this.curBpGoalFrs.length = 0;
        this.getCurrStep = null;
        this.brbpDescr = { descrRu: '', descrKK: '' };
        if (this.curRegion === null || this.curAbp === null || this.curProg === null) { return; }
        const url = `/api/budget-programs/brbp/find?plan_year=${this.curYear.year}&region=${this.curRegion.code}&abp=${this.curAbp.abp}&prg=${this.curProg.prg}`;
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
            } else {
                if (response.status === 204 && !this.addProgamMode) {
                    this.makeToast('warning', 'Загрузка данных', 'Нет данных');
                }
                if (response.status !== 200 && response.status !== 204) {
                    this.makeToast('danger', 'Ошибка загрузки данных', `${response.status} - ${response.statusText}`);
                }
                response = null;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных', error.toString());
            response = null;
        }
        this.progress2 = 20;
        this.changeDriDataIdToObj(response);
        this.brbpWrapper = response ? await response : new BrbpWrapper();
        if (!preload) {
            this.loadNpa();
            await this.loadSubProg();
            this.parseGoals();
            this.progress2 = 50;
            this.loadBpExpends();
            this.setCurDicts();
            this.progress2 = 70;
            this.loadInflProg();
        }
        this.afterChangePpr();
        this.progress2 = 90;
        this.$nextTick(() => {
            this.isUserEditMode = true;
            this.dataChanged = false;
        });
        this.isDriDataReady = true;
        this.progress2 = 0;
        this.compareCostTotals();
        this.setConcl();
        this.getbrbpCode();
        this.loadConclHist();
        this.setCurrStep();
    }

    private dictForecast: any[] = [];
    private async loadForecast() {
        try {
            const response: any = await fetch('/api/forecast');
            this.dictForecast = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника forecast', error.toString());
        }
    }

    private async loadProjects() {
        try {
            const response: any = await fetch('/api/budget-programs/bp_project');
            this.dictProjects = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника "Бюджетные программы - проекты"', error.toString());
        }
    }

    private async loadDris() {
        try {
            const response: any = await fetch('/api/budget-programs/bp_dri');
            this.dictDris = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника "Бюджетные программы - ППР"', error.toString());
        }
    }

    private async loadSee() {
        try {
            const response: any = await fetch('/api/budget-programs/bp_see');
            this.dictSee = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника "Бюджетные программы - СЭЭ"', error.toString());
        }
    }

    private async loadUnits() {
        try {
            const response: any = await fetch('/api/budget-programs/stat_unit');
            this.dictUnits = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника единиц измерения', error.toString());
        }
    }

    private changeData(ppr: any) {
        ppr.driData.forEach((dri: any) => {
            const idProject = dri.project;
            if (idProject !== null) {
                dri.project = this.dictProjects.find((p: any) => p.id === idProject);
            }
            const idDri = dri.indicator;
            if (idDri !== null) {
                dri.indicator = this.dictDris.find((p: any) => p.id === idDri);
            }
        });
        ppr.peeData.forEach((pee: any) => {
            const idProject = pee.project;
            if (idProject !== null) {
                pee.project = this.dictProjects.find((p: any) => p.id === idProject);
            }
            const idDri = pee.indicator;
            if (idDri !== null) {
                pee.indicator = this.dictDris.find((p: any) => p.id === idDri);
            }
        });
        ppr.seeData.forEach((see: any) => {
            const idProject = see.project;
            if (idProject !== null) {
                see.project = this.dictProjects.find((p: any) => p.id === idProject);
            }
            const idSee = see.see;
            if (idSee !== null) {
                see.see = this.dictSee.find((p: any) => p.id === idSee);
            }
        });
    }

    private isDriDataReady = false;
    private changeDriDataIdToObj(response: any): BrbpWrapper {
        this.loadProjects();
        this.loadDris();
        this.loadUnits();
        const res: BrbpWrapper = response;
        if (res && res.pprData !== null && res.pprData.length > 0) {
            res.pprData.forEach((ppr: any) => {
                this.changeData(ppr);
            });
        } else if (res) {
            this.changeData(res);
        }
        return res;
    }
    private delEmptyRows(ppr: BrbpWrapper) {
        const driData: BrbpDri[] = [];
       
        ppr.driData.forEach(dri => {
            if (!!dri.project
            || !!dri.planPeriod1
            || !!dri.planPeriod2
            || !!dri.planPeriod3
            || !!dri.indicator
            || !!dri.finalResult
            || !!dri.noteRu) {
                    driData.push(dri);
            }
        });
        ppr.driData = driData;

        const seeData: BrbpSee[] = [];
        ppr.seeData.forEach(see => {
            if (!!see.effPlanPeriod1 || !!see.effPlanPeriod2 || !!see.effPlanPeriod3 || !!see.see || !!see.noteRu) {
                seeData.push(see);
            }
        });
        ppr.seeData = seeData;

        const peeData: BrbpPee[] = [];
        ppr.peeData.forEach(pee => {
            if (!!pee.expendReportYear
                || !!pee.expendCorrectPlan
                || !!pee.expendPlanPeriod1
                || !!pee.expendPlanPeriod2
                || !!pee.expendPlanPeriod3
                || !!pee.noteRu) {
                peeData.push(pee);
            }
        });
        ppr.peeData = peeData;
    }

    private changeDriDataObjToId(data: BrbpWrapper): BrbpWrapper {
        const changeHelper = (ppr: any) => {
            const driData: BrbpDri[] = [];
            ppr.driData.forEach((dri: any) => {
                const objProject = dri.project;
                if (objProject && objProject.id) {
                    dri.project = objProject.id;
                }
                const objDri = dri.indicator;
                if (objDri && objDri.id) {
                    dri.indicator = objDri.id;
                }
                driData.push(dri);
            });
            ppr.driData = driData;

            const seeData: BrbpSee[] = [];
            ppr.seeData.forEach((see: any) => {
                const objProject = see.project;
                if (objProject && objProject.id) {
                    see.project = objProject.id;
                }
                const objSee = see.see;
                if (objSee && objSee.id) {
                    see.see = objSee.id;
                }
                seeData.push(see);
            });
            ppr.seeData = seeData;

            const peeData: BrbpPee[] = [];
            ppr.peeData.forEach((pee: any) => {
                const objProject = pee.project;
                if (objProject && objProject.id) {
                    pee.project = objProject.id;
                }
                peeData.push(pee);
            });
            ppr.peeData = peeData;
        };
        const res = JSON.parse(JSON.stringify(data));

        if (this.brbpWrapper.pprData.length > 0) {
            res.pprData.forEach((ppr: any) => {
                changeHelper(ppr);
            });
        } else {
            changeHelper(res);

        }
        return res;
    }

    private afterChangePpr() {
        if (!this.brbpWrapper) {
            return;
        }
        if (!this.brbpWrapper.pprData) {
            this.brbpWrapper.pprData = [];
        }
        let brbpWrapper: IBrbpWrapper | undefined;
        if (this.subProg.isNotEmpty) {
            if (!this.curSubProg || !this.curSubProg.ppr) {
                return;
            }
            brbpWrapper = this.brbpWrapper.pprData.find(i => i && i.brbp && this.curSubProg && (i.brbp.ppr === this.curSubProg.ppr));
            if (!brbpWrapper) { // добавлено так как иногда не срабатывает поиск из предыдущей строки
                this.brbpWrapper.pprData.forEach(i => {
                    if (i.brbp && this.curSubProg && (i.brbp.ppr === this.curSubProg.ppr)) {
                        brbpWrapper = i;
                    }
                });
            }
            if (!brbpWrapper) {
                brbpWrapper = new BrbpPprWrapper(
                    new Brbp(
                        this.curYear.year,
                        this.curRegion.code,
                        this.curAbp.abp,
                        this.curProg.prg,
                        this.curSubProg.ppr
                    ),
                    [new BrbpDri()],
                    [new BrbpSee()],
                    [new BrbpPee()]
                );
                this.brbpWrapper.pprData.push(brbpWrapper);
            }
        } else {
            brbpWrapper = this.brbpWrapper;

            if (!brbpWrapper) {
                brbpWrapper = new BrbpWrapper();
            }
            if (!brbpWrapper.brbp) {
                brbpWrapper.brbp
                    = new Brbp(
                        this.curYear.year,
                        this.curRegion.code,
                        this.curAbp.abp,
                        this.curProg.prg,
                        null
                    );
            }
            if (brbpWrapper.driData.isEmpty) {
                brbpWrapper.driData = [new BrbpDri()];
            }
        }
        if (!brbpWrapper.driData || brbpWrapper.driData.length === 0) {
            brbpWrapper.driData = [new BrbpDri()];
        }
        this.brbpPprSelected = brbpWrapper;
        this.$set(this, 'brbpPprSelected', brbpWrapper);
        if (this.addProgamMode && this.filter && this.filter.region && this.brbpPprSelected.brbp) this.$set(this.brbpPprSelected.brbp, 'driByRegion', this.filter.region.code.slice(-4) === '0101')

        if (this.brbpPprSelected.driData && this.brbpPprSelected.driData.length > 1) {
            this.brbpPprSelected.driData = this.brbpPprSelected.driData.sort((a: any, b: any) =>
                ((parseInt(b.region) < parseInt(a.region) ? 1 : 0) - (parseInt(a.region) < parseInt(b.region) ? 1 : 0)
                || (b.id < a.id ? 1 : 0) - (a.id < b.id ? 1 : 0))
            );
        }        
    }

    

    private async loadRegulatory() { // загрузка нормативно правовой основы
        this.regulatoryBase = [];
        let response: any = [];
        try {
            response = await fetch('/api/budget-programs/npa');
            if (response.status === 200) {
                response = await response.json();
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки нормативно правовой основы', error.toString());
        }
        response.sort((a: any, b: any) => (a.code > b.code) ? 1 : -1);
        this.regulatoryBase = response;
    }

    private async loadGovLevel() { // уровень гос управления
        let response: any = [];
        this.govLevelBase = [];
        try {
            if (this.curAbp) {
                response = await fetch(`/api/budget-programs/bp_level?abp=${this.curAbp.abp}`);
                if (response.status === 200) {
                    response = await response.json();
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки гос управления', error.toString());
        }
        response.sort((a: any, b: any) => (a.code > b.code) ? 1 : -1);
        this.govLevelBase = response;
        if (this.brbpWrapper && this.brbpWrapper.brbp && this.brbpWrapper.brbp.typeBpLevel) {
            this.curGovLevel = this.findObj(this.govLevelBase, this.brbpWrapper.brbp.typeBpLevel, 'code');
        }
    }

    private async loadContent() { // в зависимости от содержания
        let response: any = [];
        this.contentDictBase = [];
        try {
            response = await fetch('/api/budget-programs/bp_content');
            if (response.status === 200) {
                response = await response.json();
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки bp_content', error.toString());
        }
        response.sort((a: any, b: any) => (a.code > b.code) ? 1 : -1);
        this.contentDictBase = response;
        if (this.brbpWrapper && this.brbpWrapper.brbp && this.brbpWrapper.brbp.typeBpContent) {
            this.curContentDict = this.findObj(this.contentDictBase, this.brbpWrapper.brbp.typeBpContent, 'code');
        }
    }

    private async loadImplementWay() { // в зависимости от способа реализации
        let response: any = [];
        this.implementWayBase = [];
        try {
            response = await fetch('/api/budget-programs/bp_impl');
            if (response.status === 200) {
                response = await response.json();
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки способа реализации', error.toString());
        }
        response.sort((a: any, b: any) => (a.code > b.code) ? 1 : -1);
        this.implementWayBase = response;
        if (this.brbpWrapper && this.brbpWrapper.brbp && this.brbpWrapper.brbp.typeBpImpl) {
            this.curImplementWay = this.findObj(this.implementWayBase, this.brbpWrapper.brbp.typeBpImpl, 'code');
        }
    }

    private async loadBpState() { // текущее/развитие
        this.bpStateBase = [
                            {
                                id: 0,
                                code: "01",
                                name_ru: "текущая",
                                name_kk: "ағымдағы" 
                            },
                            {
                                id: 1,
                                code: "02",
                                name_ru: "развитие",
                                name_kk: "даму" 
                            }
                        ]
    }

    private async loadGoals() {
        let response: any = [];
        this.goalsBase = [];
        try {
            response = await fetch('/api/dict/program_goals');
            if (response.status === 200) {
                response = await response.json();
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки программных целей', error.toString());
        }
        response.sort((a: any, b: any) => (a.code > b.code) ? 1 : -1);
        this.goalsBase = response;
        if (this.brbpWrapper && this.brbpWrapper.brbp && this.brbpWrapper.brbp.goal) {
            this.curGoals = this.findObj(this.goalsBase, this.brbpWrapper.brbp.goal, 'code');
        }
    }

    private async loadInflProg() {
        this.descrInfl = [];
        let result: any[];
        if (this.brbpWrapper && this.brbpWrapper.brbp && this.brbpWrapper.brbp.id) {
            result = await this.loadDescrInfl(this.brbpWrapper.brbp.id);
            result = result.filter((i: any) => i.year >= this.curYear.year && i.year <= this.curYear.year + 2);
        } else {
            result = [];
        }
        for (let y = this.curYear.year; y <= this.curYear.year + 2; y++) {
            const item = result.find((infl: any) => infl.year === y);
            if (!item) {
                result.push({ year: y });
            }
        }
        result.sort((a: any, b: any) => a.year - b.year);
        this.descrInfl = result;
    }

    private async loadDescrInfl(id: number) {
        let response: any = [];
        // const result: any[] = [];
        if (!this.brbpWrapper || !this.brbpWrapper.brbp) {
            return [];
        }
        try {
            response = await fetch(`/api/budget-programs/budget_request_bp_infl?brbp=${id}`);
            if (response.status === 200) {
                response = await response.json();
            } else {
                response = [];
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки описания влияния', error.toString());
            response = [];
        }
        return response;
    }

    private async loadIndicDict() {
        let response: any = [];
        this.indicDictBase = [];
        try {
            response = await fetch('/api/dict/indicator');
            if (response.status === 200) {
                response = await response.json();
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки показателей', error.toString());
        }
        this.indicDictBase = response;
    }

    private async loadNpa() { // загрузка нормативно правовой основы для программы
        let response: any = [];
        this.curRegulatory = [];
        if (!this.brbpWrapper || !this.brbpWrapper.brbp) {
            return;
        }
        try {
            response = await fetch(`/api/budget-programs/budget_request_bp_npa?brbp=${this.brbpWrapper.brbp.id}`);
            if (response.status === 200) {
                response = await response.json();
                for (const el of response) {
                    this.curRegulatory.push(el.id);
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки НПА по БЗБП', error.toString());
        }
    }

    // Расходы по БП
    private bpExpendsData: any = {};

    public async loadBpExpends() {
        if (!this.filter.region) {
            return;
        }
        this.bpExpendsData = {};
        let result: any = null;
        try {
            const response: any = await fetch(`/api/budget-programs/budget_request_bp/bpe?curr_year=${this.filter.year}&region=${this.filter.region.code}&abp=${this.filter.abp.abp}&prg=${this.filter.prg.prg}`);
            if (response.status === 200) {
                result = await response.json();
                result.sort((a: any, b: any) => a.ppr - b.ppr);
                const totalSum = { 'kassRas': 0, 'utochPlan': 0, planPeriod1: 0, planPeriod2: 0, planPeriod3: 0 };
                for (const rec of result) {
                    totalSum.kassRas += rec.kassRas;
                    totalSum.utochPlan += rec.utochPlan;
                    totalSum.planPeriod1 += rec.planPeriod1;
                    totalSum.planPeriod2 += rec.planPeriod2;
                    totalSum.planPeriod3 += rec.planPeriod3;
                }
                this.bpExpendsData = {
                    tableData: result,
                    totalSum: totalSum
                };
                this.compareCostTotals();
            } else {
                this.makeToast('danger', 'Ошибка загрузки данных расходов по бюджетной программе', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных расходов по бюджетной программе', error.toString());
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private async getObl() {
        let obl = null;
        const instCode = store.getters.user_uuid;
        try {
            const result = await fetch('/api-py/get-budget-obl/' + instCode)
            if (result.status === 200) {
                const json = await result.json();
                obl = json.obl;
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            };
        } catch (error) {
            obl = null;
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', error.toString());
        }
        if (!this.usrId) { return; }
        this.regionBase = [];
        if (obl !== null) {
            try {
                await fetch('/api-py/get-user-regions-by-obl/' + obl + '/' + this.usrId)
                    .then(response => response.json())
                    .then(json => {
                        if (json.length && json[0].code) {
                            json.sort((a: any, b: any) => (a.code - b.code > 0) ? 1 : -1);
                            this.regionBase = json;
                        }
                    });
            } catch (error) {
                this.regionBase = [];
                this.makeToast('danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
            }
            // if (instCode) {
            //     try {
            //         this.regionBase = await fetch(`/api/budget-programs/budget_request_bp/budget_regions?user_uuid=${instCode}`)
            //             .then(response => response.json());
            //     } catch (error) {
            //         this.regionBase = [];
            //         this.makeToast('danger', 'Ошибка запроса get-regions-by-obl', error.toString());
            //     }
            // } else {
            //     try {
            //         this.regionBase = await fetch('/api/budget-programs/budget_request_bp/budget_regions')
            //             .then(response => response.json());
            //     } catch (error) {
            //         this.regionBase = [];
            //         this.makeToast('danger', 'Ошибка запроса get-regions-by-obl', error.toString());
            //     }
            // }
        }
        this.region();
        this.progress = 20;
    }

    // получение всех программ
    private allPrgsList: any = [];
    private async getAllPrgs() {
        try {
            this.allPrgsList = await fetch(`api/budget-programs/ebk_func/prg?abp=${this.curAbp.abp}`)
                .then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса ebk_func/prg', error.toString());
        }
    }

    // получение всех групп
    private allGrpsList: any = [];
    private async getAllGrps() {
        try {
            this.allGrpsList = await fetch('api/budget-programs/ebk_func/gr')
                .then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса ebk_func/gr', error.toString());
        }
    }

    // 
    private avlblGrpsList: any = [];
    private async getAvlblGrps() {
        if (!this.usrId) return; 
        try {
            this.avlblGrpsList = await fetch(`/api-py/user-fgr/${this.usrId}`)
                .then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса user-fgr/', error.toString());
        }
    }

    // получение всех групп
    private allAbpList: any = [];
    private async getAllAbps() {
        try {
            this.allAbpList = await fetch('api/budget-programs/ebk_func/abp')
                .then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса ebk_func/abp', error.toString());
        }
    }

    private mainTableOverlay: boolean = false;
    private async uploadData(save = false) {
        if (!this.curRegion || !this.curYear || !this.curAbp) {
            return;
        }
        this.mainTableOverlay = true;
        if (!save) {
            this.isAllOpen = false;
        }
        let bps: any = [];
        if (this.curYear === null || this.curRegion === null || this.curAbp === null) { return; }
        try {
            bps = await fetch(`/api/budget-programs/brbps?plan_year=${this.curYear.year}&region=${this.curRegion.code}&abp=${this.curAbp.abp}`)
                .then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса brbps', error.toString());
            return;
        }
        
        this.progress = 40;
        await this.getAllPrgs();
        this.progress = 60;
        let actualPrgsList: any = [];
        let program: any = [];
        let abpList: any = [];
        if (bps.length > 0 && this.allPrgsList.length > 0) {
            // получаем все программы
            bps.forEach((bp: any) => {
                if (!bp.ppr) {
                    program = this.allPrgsList.filter((prg: any) => prg.prg === bp.prg
                        && prg.abp === this.curAbp.abp
                        && prg.type === 4);
                    program.forEach((prog: any) => {
                        if (this.avlblGrpsList.find((ag: any) => ag.fgr === prog.gr)) {
                            abpList.push({ abp: prog.abp, gr: prog.gr });
                            actualPrgsList.push({ ...prog, bpId: bp.id, goal: bp.goal, conclExpendCode: bp.conclExpendCode, conclFrCode: bp.conclFrCode, approveStatus: bp.approveStatus });
                        }
                    });
                }
            });
            // получаем все абп
            await this.getAllAbps();
            this.progress = 90;
            abpList = this.removeDuplicates(abpList);
            abpList.forEach((prog: any) => {
                actualPrgsList = [...actualPrgsList, this.allAbpList.filter((item: any) => item.abp === prog.abp && item.gr === prog.gr)[0]];
            });
            // получение групп
            const grId: any = [];
            actualPrgsList.forEach((prg: any) => {
                if (grId.indexOf(prg.gr) === -1) {
                    grId.push(prg.gr);
                    actualPrgsList = [...actualPrgsList, ...this.allGrpsList.filter((gr: any) => gr.gr === prg.gr)];
                }
            });
        }
        this.loadProg();
        this.progress = 95;
        this.preparingTableData(actualPrgsList, save);
        this.progress = 0;
        this.loadConclDict();
        this.mainTableOverlay = false;
    }

    private preparingTableData(data: any, save: boolean) {
        const newData: any = [];
        data.forEach((row: any) => {
            newData.push(this.setNameLang(row));
        });

        newData.sort((a: any, b: any) => (b.gr < a.gr ? 1 : 0) - (a.gr < b.gr ? 1 : 0)
                                        || (b.abp < a.abp ? 1 : 0) - (a.abp < b.abp ? 1 : 0)
                                        || (b.prg < a.prg ? 1 : 0) - (a.prg < b.prg ? 1 : 0)
                                        || (b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0)
        );

        this.tableFieldsItems = newData;
        if (!save) {
            this.closeAll();
        }
        this.tableFieldsItems.forEach((row: any) => {
            row.open = false;
        });
        if (!save) {
            this.preloadData();
        }
    }
    private removeDuplicates(arr: any) {
        const result: any[] = [];
        const duplicatesIndices: any[] = [];

        // Перебираем каждый элемент в исходном массиве
        arr.forEach((current: any, index: number) => {
            if (duplicatesIndices.includes(index)) { return; }

            result.push(current);

            // Сравниваем каждый элемент в массиве после текущего
            for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
                const comparison = arr[comparisonIndex];
                const currentKeys = Object.keys(current);
                const comparisonKeys = Object.keys(comparison);

                // Проверяем длину массивов
                if (currentKeys.length !== comparisonKeys.length) { continue; }

                // Проверяем значение ключей
                const currentKeysString = currentKeys.sort().join('').toLowerCase();
                const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase();
                if (currentKeysString !== comparisonKeysString) { continue; }

                // Проверяем индексы ключей
                let valuesEqual = true;
                for (let i = 0; i < currentKeys.length; i++) {
                    const key = currentKeys[i];
                    if (current[key] !== comparison[key]) {
                        valuesEqual = false;
                        break;
                    }
                }
                if (valuesEqual) { duplicatesIndices.push(comparisonIndex); }
            } // Конец цикла
        });
        return result;
    }

    // решает баг с отображением данных в режиме редактирования при первом входе
    private preloadData() {
        if (this.tableFieldsItems.length > 2) {
            this.goal = this.tableFieldsItems[2].goal;
            this.bpId = this.tableFieldsItems[2].bpId;
            this.curProg = this.tableFieldsItems[2];
            this.loadData(true);
        }
    }

    private isAllOpen = false;
    private closeAll() {
        this.isAllOpen = false;
        this.openAll();
    }
    private openAll() {
        this.isAllOpen = !this.isAllOpen;
        if (this.isAllOpen) {
            this.tableFieldsItems.forEach((row: any) => {
                if (row.type === 1) {
                    row.collapsed = false;
                    row.open = false;
                } else {
                    row.collapsed = true;
                    row.open = false;
                }
            });
        } else {
            this.tableFieldsItems.forEach((row: any) => {
                row.collapsed = false;
                row.open = true;
            });
        }
    }

    private toggleChilds(item: any, type: number) {
        this.tableFieldsItems = this.tableFieldsItems.map((row: any) => {
            if (type === 3 && row.gr === item.gr && (row.type === type)) {
                row.collapsed = !row.collapsed;
            }

            if (type === 4 && row.gr === item.gr && (row.type === type)) {
                row.collapsed = !row.collapsed;
            }

            if (row.id === item.id) {
                row.open = !row.open;
            }
            if (row.gr === item.gr && !item.open) {
                if (row.type === type) {
                    row.open = false;
                }
                if (row.type === type + 1) {
                    row.collapsed = true;
                    row.open = false;
                }
            }
            return row;
        });
    }

    private editMode = false;
    private onEdit(item: any) {
        this.addProgamMode = false;
        this.curPrgId = item.prg;
        this.goal = item.goal;
        this.bpId = item.bpId;
        this.curProg = item;
        this.editMode = true;
        this.$emit('setBpInEditMode', true);
        this.chgProg();
        this.switchPages();
    }

    private created() {
        this.progress = 0;
        this.watchOnDataEdit();
        // this.loadAbp();
        this.getObl();
        this.loadRegulatory();
        this.loadContent();
        this.loadImplementWay();
        this.loadBpState();
        this.loadGoals();
        this.loadIndicDict();
        this.getAllGrps();
        this.loadSee();
        this.uploadConclAtr();
        this.$root.$emit('removeClass');
    }

    private watchOnDataEdit() {
        const that = this;
        this.$watch('searchNPA', function () { if (!that.searchNPA) { that.onSearchNPA(); } });
        this.$watch('searchBP', function () { if (!that.searchBP) { that.onSearchBP(); } });
        this.$watch('curRegion', that.loadBudgetLevel);
        this.$watch('brbpWrapper', that.onDataChanged_brbpWrapper, { deep: true });
        this.$watch('curFinalResults', that.onDataChanged);
        this.$watch('dataChanged', that.onChangeDataChanged);
        this.$watch('curAbp', that.loadGovLevel);
        this.$watch('$store.state.user.sub', function() {that.getObl(), that.loadBudgetLevel() });
        this.$watch('$store.state._instanceCode', that.getObl, { deep: true });
        this.$watch('aggrLevelCode', that.setCurrStep);
        this.$watch('aggrLevelCode', that.setCurrStepinTable);
    }

    private onChangeDataChanged(oldValue: any, newValue: any) {
        if (newValue === true) {
            // console.log(`${new Date()} onChangeDataChanged() oldValue=${oldValue}, newValue=${newValue}`);
        }
    }
    
    private onDataChanged(oldValue: any, newValue: any) {
        if (this.isUserEditMode && !this.dataChanged) {
            this.dataChanged = true;
        }
    }

    private onDataChanged_brbpWrapper(oldValue: any, newValue: any) {
        if (newValue instanceof BrbpWrapper) {
            return;
        }

    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private isValid(brbpWrapper: BrbpWrapper): string {
        let emptyInd = false;
        brbpWrapper.pprData.forEach(p =>
            p.driData.forEach(d => {
                if (!d.indicator) {
                    emptyInd = true;
                }
            }
            )
        );
        let res = '';
        if (emptyInd) {
            res = res + 'ППР';
        }
        if (res) {
            return `В разделе 'Показатели прямого результата' присутствуют данные без ${res}`;
        }
        return '';
    }

    private formFlk(): string | null {
        let res: string | null = null;
        const reqFields: string[] = [];
        if (!this.curGovLevel) {
            reqFields.push('Вид БП: в зависимости от уровня государственного управления');
        }
        if (!this.curContentDict) {
            reqFields.push('Вид БП: в зависимости от содержания');
        }
        if (!this.curImplementWay) {
            reqFields.push('Вид БП: в зависимости от способа реализации');
        }
        if (reqFields.isNotEmpty) {
            const fields = reqFields.map(s => `"${s}`).join(', ');
            res = `Не заполнены обязательные для заполнения поля: ${fields}`;
        }
        return res;
    }
    // проверка заполненности полей перед сохраннением
    private disabledSaveBtn = false;
    private ifAllFieldsFilled() {        
        this.disabledSaveBtn = true;
        const missedFields = [];
        this.showTop = false;
        // проверка таблицы ппр
        const missedFR = { ppr: null, isEmpty: false };
        const missedInd = { ppr: null, isEmpty: false };
        const missedUnit = { ppr: null, isEmpty: false };
        const missedSee = { ppr: null, isEmpty: false };
        let frWithoutInd: any[] = [];
        // проверка заполненности обязательных полей
        const checkFields = (obj: any) => {
            for (const [iDri, dri] of obj.driData.entries()) {
                // проверка заполненности обязательных полей
                if (!dri.finalResult && this.finalResultTableData) {
                    missedFR.isEmpty = true;
                    missedFR.ppr = obj.brbp.ppr;
                }
                if (!dri.indicator || !dri.indicator.name_ru) {
                    missedInd.isEmpty = true;
                    missedInd.ppr = obj.brbp.ppr;
                }
                if (!dri.indicator || !dri.indicator.unit) {
                    missedUnit.isEmpty = true;
                    missedUnit.ppr = obj.brbp.ppr;
                }
                if ((!dri.indicator || !dri.indicator.name_ru) && dri.finalResult) {
                    const frName = this.curFinalResults.filter((item: any) => item.code === dri.finalResult)[0].name_ru;
                    frWithoutInd.push(frName);
                }
            }

            obj.seeData.forEach((see: any) => {
                if ((!see.see || !see.see.name_ru) && (!!see.effPlanPeriod1 || !!see.effPlanPeriod2 || !!see.effPlanPeriod3)) {
                    missedSee.isEmpty = true;
                    missedSee.ppr = obj.brbp.ppr;
                }
            });

        }
        if (this.brbpWrapper.pprData.length > 0) {
            this.brbpWrapper.pprData.forEach((ppr: any, iPpr: number) => {
                this.delEmptyRows(ppr);
                checkFields(ppr);
            });
        } else {
            this.delEmptyRows(this.brbpWrapper);
            checkFields(this.brbpWrapper);
        }

        frWithoutInd = [...new Set(frWithoutInd)];
        if (missedFR.isEmpty) {
            missedFields.push(`Табл. ППР${missedFR.ppr ? '(' + missedFR.ppr + ')' : ''} - Конечный результат`);
        }
        if (missedInd.isEmpty) {
            missedFields.push(`Табл. ППР${missedInd.ppr ? '(' + missedInd.ppr + ')' : ''} - Показатель ППР`);
        }
        if (missedUnit.isEmpty) {
            missedFields.push(`Табл. ППР${missedUnit.ppr ? '(' + missedUnit.ppr + ')' : ''} - Ед.измерения`);
        }
        if (missedSee.isEmpty) {
            missedFields.push(`Табл. ППР${missedSee.ppr ? '(' + missedSee.ppr + ')' : ''} - "Эффект"`);
        }
        if (this.curRegulatory.length === 0) {
            missedFields.push('Нормативная правовая основа БП');
        }
        if (!this.curGovLevel || !this.curContentDict || !this.curImplementWay) {
            missedFields.push('Вид БП');
        }

        if (this.bpGoalsList.length > 0 && !this.curBpGoal) {
            missedFields.push('Цель БП');
        }
        if (!this.curSubProg && this.subProg.length > 0) {
            missedFields.push('Код и наименование БПП');
        }
        if (missedFields.length > 0) {
            const errorMsg = missedFields.join(';  ');
            this.makeToast('warning', 'Предупреждение', `Необходимо заполнить следующие разделы: ${errorMsg}`);
            if (frWithoutInd.length > 0) {
                const frWithoutIndList = frWithoutInd.join(';  ');
                this.makeToast('warning', 'Предупреждение', `Не определены показатели прямого результата для следующих целевых индикаторов (конечных результатов): ${frWithoutIndList}`);
            }
            this.disabledSaveBtn = false;
        } else {
            this.save();
        }
    }
    private async save() {
        const removeTempId = (item: any, i?: number) => {
            item.driData.forEach((dri: any, z: number) => {
                if (dri.id) {
                    const strId = dri.id.toString();
                    if (strId.length >= 13) {
                        if (i !== undefined) {
                            delete this.brbpWrapper.pprData[i].driData[z].id;
                        } else {
                            delete this.brbpWrapper.driData[z].id;
                        }
                    }
                }
            });
            item.peeData.forEach((pee: any, z: number) => {
                if (pee.id) {
                    const strId = pee.id.toString();
                    if (strId.length >= 13) {
                        if (i !== undefined) {
                            delete this.brbpWrapper.pprData[i].peeData[z].id;
                        } else {
                            delete this.brbpWrapper.peeData[z].id;
                        }
                    }
                }
            });
            item.seeData.forEach((see: any, z: number) => {
                if (see.id) {
                    const strId = see.id.toString();
                    if (strId.length >= 13) {
                        if (i !== undefined) {
                            delete this.brbpWrapper.pprData[i].seeData[z].id;
                        } else {
                            delete this.brbpWrapper.seeData[z].id;
                        }
                    }
                }
            });
        };
        if (this.brbpWrapper.pprData.length > 0) {
            this.brbpWrapper.pprData.forEach((item: any, i: number) => {
                removeTempId(item, i);
            });
        } else {
            removeTempId(this.brbpWrapper);
        }
        // this.isUserEditMode = false;
        if (this.curRegion === null || this.curProg === null) { return; }
        let methServ = 'POST';
        if (this.brbpWrapper.brbp && this.brbpWrapper.brbp.id) {
            methServ = 'PUT';
        }

        if (!this.brbpWrapper.brbp) {
            this.brbpWrapper.brbp = new Brbp(this.curYear.year, this.curRegion.code, this.curAbp.abp, this.curProg.prg, null);
        }
        if (this.curGovLevel !== null) { this.brbpWrapper.brbp.typeBpLevel = this.curGovLevel.id; }
        if (this.curContentDict !== null) { this.brbpWrapper.brbp.typeBpContent = this.curContentDict.id; }
        if (this.curImplementWay !== null) { this.brbpWrapper.brbp.typeBpImpl = this.curImplementWay.id; }
        if (this.curBpGoal !== null) { this.brbpWrapper.brbp.goal = this.curBpGoal.id; }
        if (this.brbpDescr.descrKK && this.brbpDescr.descrKK.trim().length > 0) { this.brbpWrapper.brbp.descrKk = this.brbpDescr.descrKK.trim(); }
        if (this.brbpDescr.descrRu && this.brbpDescr.descrRu.trim().length > 0) { this.brbpWrapper.brbp.descrRu = this.brbpDescr.descrRu.trim(); }
        if (this.curAbp) { this.brbpWrapper.brbp.abp = this.curAbp.abp; }
        if (this.curProg) { this.brbpWrapper.brbp.prg = this.curProg.prg; }

        if (this.conclusions.conclNpaRu) { this.brbpWrapper.brbp.conclNpaRu = this.conclusions.conclNpaRu; }
        if (this.conclusions.conclDescrRu) { this.brbpWrapper.brbp.conclDescrRu = this.conclusions.conclDescrRu; }
        if (this.conclusions.conclFrRu) { this.brbpWrapper.brbp.conclFrRu = this.conclusions.conclFrRu; }
        if (this.conclusions.conclExpendRu) { this.brbpWrapper.brbp.conclExpendRu = this.conclusions.conclExpendRu; }
        if (this.conclusions.conclDriRu) { this.brbpWrapper.brbp.conclDriRu = this.conclusions.conclDriRu; }
        
        if (this.conclusions.conclNpaCode) { this.brbpWrapper.brbp.conclNpaCode = this.conclusions.conclNpaCode; }
        if (this.conclusions.conclDescrCode) { this.brbpWrapper.brbp.conclDescrCode = this.conclusions.conclDescrCode; }
        if (this.conclusions.conclFrCode) { this.brbpWrapper.brbp.conclFrCode = this.conclusions.conclFrCode; }
        if (this.conclusions.conclExpendCode) { this.brbpWrapper.brbp.conclExpendCode = this.conclusions.conclExpendCode; }
        const dataForSave = this.changeDriDataObjToId(this.brbpWrapper);
        // очистка от пустых строк раздела ППР
        dataForSave.pprData.forEach((it: any) => {
            it.driData = it.driData.filter((p: any) => p.budgetRequestBp
                || p.region || p.project || p.indicator || p.finalResult || p.reportYear
                || p.correctPlan || p.planPeriod1 || p.planPeriod2 || p.planPeriod3
            );
        });
        dataForSave.driData = dataForSave.driData.filter((p: any) => p.budgetRequestBp
            || p.region || p.project || p.indicator || p.finalResult || p.reportYear
            || p.correctPlan || p.planPeriod1 || p.planPeriod2 || p.planPeriod3
        );
        try {
            const url = '/api/budget-programs/brbp';
            let response: any = await fetch(url, {
                method: methServ,
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(dataForSave) // body data type must match "Content-Type" header
            });
            if (response.status === 200) {
                this.makeToast('success', 'Сохранение', 'Данные сохранены');
                response = await response.json();
                this.brbpWrapper = JSON.parse(JSON.stringify(response))
                this.changeDriDataIdToObj(this.brbpWrapper);
                this.afterChangePpr();
                this.getbrbpCode();
                await this.saveNPA(response.brbp.id);
                this.saveProgInfl(response.brbp.id);
                this.uploadData(true);
                const child = this.$refs.driEditModule as Vue & { updateTables: () => void };
                child.updateTables();
            } else {
                this.makeToast('warning', 'Сохранение', `${response.status} - ${response.statusText}`);
            }
            this.dataChanged = false;
        } catch (error) {
            this.makeToast('danger', 'Ошибка Сохранения', error.toString());
        }
        this.disabledSaveBtn = false;
    }

    private async saveNPA(id: number) {
        try {
            const url = `/api/budget-programs/budget_request_bp_npa?brbp=${id}`;
            const response: any = await fetch(url, {
                method: 'PUT',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(this.curRegulatory) // body data type must match "Content-Type" header
            });
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения НПА', error.toString());
        }
    }

    private async saveProgInfl(id: number) {
        await this.saveDescrInfl(this.descrInfl, id);
    }

    private async saveDescrInfl(inflObj: any[], id: number) {
        const saveObj: any[] = [];
        for (let i = 0; i < inflObj.length; i++) {
            saveObj.push(Object.assign({ year: (this.curYear.year + i + 1) }, inflObj[i]));
        }
        try {
            const url = `/api/budget-programs/budget_request_bp_infl?brbp=${id}`;
            const response: any = await fetch(url, {
                method: 'PUT',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения saveDescrInfl', error.toString());
        }
    }

    private setCurDicts() { // расставить значения из brbp
        if (!this.brbpWrapper || !this.brbpWrapper.brbp) {
            return;
        }
        this.curGovLevel = this.findObj(this.govLevel, this.brbpWrapper.brbp.typeBpLevel);
        this.curContentDict = this.findObj(this.contentDict, this.brbpWrapper.brbp.typeBpContent);
        this.curImplementWay = this.findObj(this.implementWay, this.brbpWrapper.brbp.typeBpImpl);
        // --
        this.curGoals = this.findObj(this.goals, this.brbpWrapper.brbp.goal);
        // await this.loadFinalResultBase();
        if (this.brbpWrapper.brbp.descrRu !== null) { this.brbpDescr.descrRu = this.brbpWrapper.brbp.descrRu!!; }
        if (this.brbpWrapper.brbp.descrKk !== null) { this.brbpDescr.descrKK = this.brbpWrapper.brbp.descrKk!!; }
    }

    private findObj(arr: any[], id?: number, codeName?: string | null) {
        if (!id) {
            return null;
        }
        if (arr === null || id === null) { return null; }
        for (const el of arr) {
            if (el.id === id) {
                if (el.name === undefined) {
                    return this.setNameLang(el, codeName);
                }
                return el;
            }
        }
        return null;
    }

    private setFilter() {
        this.filter = [];
        if (this.curAbp === null || this.curProg === null || this.curYear === null) {
            return;
        }
        const filter = {
            spf: null,
            from: null,
            gu: null,
            dataType: { code: '1' },
            abp: this.curAbp,
            prg: this.curProg,
            pgr: this.curSubProg,
            year: this.curYear.year,
            region: this.curRegion,
            bpState: this.curBpState,
            isPrgDev: this.isPrgDev,
            curBpGoal: this.curBpGoal
        };
        this.$set(this, 'filter', filter);
    }

    // ============ breadcrumbs ==========
    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    // переход между таблицами
    private selectedTable = 'ppr';
    private tables = [
        { text: 'Показатели прямого результата', value: 'ppr' },
        { text: 'Показатели экономического эффекта', value: 'eff' },
        { text: 'Расходы', value: 'cost' }
    ];

    private forceRerender = 0
    private compareCostTotals() {
        const pprTotals: any[] = [];
        if (this.bpExpendsData.pprTotals) {
            delete this.bpExpendsData.pprTotals;
        }
        if (this.brbpWrapper.pprData
             && this.brbpWrapper.pprData.length > 0) {
            this.brbpWrapper.pprData.forEach((ppr: any) => {
                const onePprTotals = {
                    ppr: null,
                    sumCorrectPlan: 0,
                    sumPlanPeriod1: 0,
                    sumPlanPeriod2: 0,
                    sumPlanPeriod3: 0,
                    sumReportYear: 0
                };
                if (ppr.brbp && ppr.brbp.ppr) {
                    onePprTotals.ppr = ppr.brbp.ppr;
                    if (this.bpExpendsData && this.bpExpendsData.tableData) {
                        if (this.bpExpendsData.tableData.findIndex((item: any) => item.ppr === ppr.brbp.ppr) === -1) {
                            this.bpExpendsData.tableData.push({
                                kassRas: null,
                                planPeriod1: null,
                                planPeriod2: null,
                                planPeriod3: null,
                                ppr: onePprTotals.ppr,
                                utochPlan: null
                            });
                        }
                    }
                }
                if (ppr.peeData && ppr.peeData.length > 0) {
                    ppr.peeData.forEach((pee: any) => {
                        if (pee.expendCorrectPlan) { onePprTotals.sumCorrectPlan += Number(pee.expendCorrectPlan); }
                        if (pee.expendPlanPeriod1) { onePprTotals.sumPlanPeriod1 += Number(pee.expendPlanPeriod1); }
                        if (pee.expendPlanPeriod2) { onePprTotals.sumPlanPeriod2 += Number(pee.expendPlanPeriod2); }
                        if (pee.expendPlanPeriod3) { onePprTotals.sumPlanPeriod3 += Number(pee.expendPlanPeriod3); }
                        if (pee.expendReportYear) { onePprTotals.sumReportYear += Number(pee.expendReportYear); }
                    });    
                }
                pprTotals.push(onePprTotals);
            });
        }
        this.bpExpendsData.pprTotals = pprTotals;
        ++this.forceRerender;
    }

    // выход из режима редактирования программы
    private onExitEditMode() {
        if (this.dataChanged) {
            this.$bvModal.show('cancel-modal');
        } else {
            this.switchPages('exitEditMode');
        }
    }

    private regionCityTrigger = 0;
    private onChangeRegionCity(oldValue: boolean, newValue: any) {
        this.regionCityTrigger += 1;
        this.onDataChanged(oldValue, newValue);
    }

    private addZero(numb: number) {
        if (numb >= 100) return numb;
        if (numb >=10) return '0' + numb.toString();
        else return '00' + numb.toString();
    }

    // загрузка отчета
    private isDownloadDisabled = false;
    private async bpReportGeneration(reportNumber: number, isAbpReport = false) {
        this.emitDownloadDisabled(isAbpReport, true);
        const params = JSON.stringify({
            curYear: parseInt(this.curYear.year),
            region: this.curRegion.code,
            abp: this.curAbp.abp,
            prg: this.curPrgId ? this.curPrgId : null,
            ppr: this.curSubProg ? this.curSubProg.ppr : null,
            instCode: this.$store.getters.user_uuid,
            reportNumber: reportNumber
        });
        let urls = '/api-py/bp_generations/' + encodeURI(params);
        let text = ''
        if  (this.curAbp.abp && this.curPrgId) text = `${this.addZero(this.curAbp.abp)} ${this.addZero(this.curPrgId)}.xls`;        
        let filename = `БП ${text}`;
        switch (reportNumber) {
            case 2:
                urls = '/api-py/bpconcl_generations/' + encodeURI(params);
                filename = `Заключение по БП ${text}.xls`;
                break;
            case 3:
                urls = '/api-py/abpconcl_gen/' + encodeURI(params);
                filename = `Заключение по АБП ${this.addZero(this.curAbp.abp)}.xls`;
                break;
            case 4:
                urls = '/api-py/abpconcl_gen/' + encodeURI(params);
                filename = `Заключение по АБП ${this.addZero(this.curAbp.abp)} без РБ.xls`;
                break;
        }
        Ax(
            {
                url: urls, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);// or any other extension
                document.body.appendChild(link);
                link.click();
                this.emitDownloadDisabled(isAbpReport, false);
            },
            (error) => {
                //this.error = error;
                this.makeToast('danger', 'Ошибка загрузки отчета', error.toString()); 
                this.emitDownloadDisabled(isAbpReport, false);
            }
        );
        
    }

    private emitDownloadDisabled(isAbpReport: boolean, val: boolean) {
        this.isDownloadDisabled = val;
        if (isAbpReport) this.$emit('setDownloadDisabled', val);
    }

    private conclusions: any = {
        conclNpaRu: null,
        conclDescrRu: null,
        conclFrRu: null,
        conclExpendRu: null,
        conclDriRu: null,
        conclNpaCode: null,
        conclDescrCode: null,
        conclFrCode: null,
        conclExpendCode: null,
    };

    private setConcl() {
        if (this.brbpWrapper.brbp) {
            if (this.brbpWrapper.brbp.conclNpaRu) {
                this.conclusions.conclNpaRu = this.brbpWrapper.brbp.conclNpaRu;
            }
            if (this.brbpWrapper.brbp.conclDescrRu) {
                this.conclusions.conclDescrRu = this.brbpWrapper.brbp.conclDescrRu;
            }
            if (this.brbpWrapper.brbp.conclFrRu) {
                this.conclusions.conclFrRu = this.brbpWrapper.brbp.conclFrRu;
            }
            if (this.brbpWrapper.brbp.conclExpendRu) {
                this.conclusions.conclExpendRu = this.brbpWrapper.brbp.conclExpendRu;
            }
            if (this.brbpWrapper.brbp.conclDriRu) {
                this.conclusions.conclDriRu = this.brbpWrapper.brbp.conclDriRu;
            }
            if (this.brbpWrapper.brbp.conclNpaCode) {
                this.conclusions.conclNpaCode = this.brbpWrapper.brbp.conclNpaCode;
            }
            if (this.brbpWrapper.brbp.conclDescrCode) {
                this.conclusions.conclDescrCode = this.brbpWrapper.brbp.conclDescrCode;
            }
            if (this.brbpWrapper.brbp.conclFrCode) {
                this.conclusions.conclFrCode = this.brbpWrapper.brbp.conclFrCode;
            }
            if (this.brbpWrapper.brbp.conclExpendCode) {
                this.conclusions.conclExpendCode = this.brbpWrapper.brbp.conclExpendCode;
            }
        }
    }

    private infoCostForm(infoId: number) {
        Ax(
            {
                url: '/api-py/get-info/' + infoId, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                const file = 'РП Бюджетные программы';
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка загрузки руководства пользователя', error.toString());
            }
        );
    }

    private selectedConcl1: any = null;
    private selectedConcl2: any = null;
    private conclAttributes1 = [];
    private conclAttributes2 = [];

    private async uploadConclAtr() {
        try {
            const response1 = await fetch(`/api/budget-programs/bp_conclusion_type1`);
            if (response1.status === 200) {
                this.conclAttributes1 = await response1.json()
                this.conclAttributes1.sort((a: any, b: any) => a.code - b.code)
            } 
            const response2 = await fetch(`/api/budget-programs/bp_conclusion_type2`);
            if (response2.status === 200) {
                this.conclAttributes2 = await response2.json()
                this.conclAttributes2.sort((a: any, b: any) => a.code - b.code)
            } 
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки bp_conclusion_type', error.toString());
        }
    }

    private get localName() {
      return this.getLocalName()
    }

    private getLocalName() {
          let fieldName = 'name_ru';
        if (this.$i18n.locale === 'kk') {
            fieldName = 'name_kk';
        };
        return fieldName;
    }

    private getAttrText(code: string, dicType: number): string {
        const dict = dicType === 1 ? this.conclAttributes1 : this.conclAttributes2;
        const fieldName = this.getLocalName();
        if (dict) {
            const dictRow = dict.filter((rec: any) => rec.code === code)[0];
            if (dictRow) {
                return dictRow[fieldName];
            }
        } 
        return '';
    }

    // управление состояниями при согласовании
    
    private get itputState() {
        if (this.onlyReadMode) return 'budgetprogramdisabled'
        if (!this.bpMode) {
            if ([5, 7, 10, 13, 14, 16, 19, 20].includes(this.aggrLevelCode)) return 'budgetprogramdisabled';
            return null;
        } 
        return 'budgetprogramdisabled';
    }
    private get conclState() {
        if (this.onlyReadMode) return 'budgetprogramdisabled'
        if (!this.showConcl) return 'budgetprogramhidden';
        if (this.bpMode) {
            if ([6, 7, 11, 14, 17, 20].includes(this.aggrLevelCode)) return 'budgetprogramdisabled';
            return null;
        } else {
            if ([1, 5].includes(this.aggrLevelCode)) return 'budgetprogramhidden';
            return 'budgetprogramdisabled';
        }
    }

    private canBeDeleted(item: any) {
        if ([5, 7, 10, 13, 14, 16, 19, 20].includes(parseInt(item.approveStatus))) return true;
        return false
    }

    // Согласование
    private conclDict: any[] | null = null;
    private async loadConclDict() {
        let response: any = [];
        try {
            response = await fetch(`/api-py/get-all-agreement-status/%7B%22mode_code%22:%22prg%22%7D`);
            if (response === null) {
                this.makeToast('danger', 'Ошибка загрузки статусов', `${response}`);
                response = [];
            } else if (response.status === 200) {
                response = await response.json();
                this.conclDict = response;
            } else {
                this.makeToast('danger', 'Ошибка загрузки статусов', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки статусов', (error as Error).toString());
        }
    }

    private async saveConclStep(body: any) {
        if (!body) return;
        try {
            const url = '/api/budget-programs/approve_status';
            const response: any = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(body) // body data type must match "Content-Type" header
            });
            if (response.status === 200) {
                this.makeToast('success', 'Статус сохранен', 'Статус сохранен');  
                this.loadConclHist();
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка Сохранения', error.toString());
        }
    }

    private aggHistory: any[] = []
    private async loadConclHist() {
        if (!this.brbpCode) return;
        let response: any = [];
        try {
            response = await fetch(`/api/budget-programs/approve_status/${this.brbpCode}`);
            if (response === null) {
                this.makeToast('danger', 'Ошибка загрузки статусов', `${response}`);
                response = [];
            } else if (response.status === 200) {
                response = await response.json();
                this.aggHistory = response.sort((a:any, b: any) => { return a.updateTime - b.updateTime });
            } else {
                this.makeToast('danger', 'Ошибка загрузки статусов', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки статусов', (error as Error).toString());
        }
    }

    private get agrCodesDict() {
        if (!this.conclDict) return [];
        const codesList: any[] = [];
        this.conclDict.forEach((item: any) => {
            if (item.stepBack.length || item.stepForward.length) {
                const stepBack = item.stepBack.length ? item.stepBack[0].step_code : null;
                const stepForward = item.stepForward.length ? item.stepForward[0].step_code : null;
                const codeListItem: any = {};
                this.$set(codeListItem, 'id', item.id);
                this.$set(codeListItem, 'code', item.code);
                this.$set(codeListItem, 'name_ru', item.name_ru);
                this.$set(codeListItem, 'name_kk', item.name_kk);
                this.$set(codeListItem, 'prev_step', stepBack);
                this.$set(codeListItem, 'next_step', stepForward);
                this.$set(codeListItem, 'btn_name_kk', item.btn_name_kk);
                this.$set(codeListItem, 'btn_name_ru', item.btn_name_ru);
                codesList.push(codeListItem)
            }
        })
        return codesList;
    }

    private brbpCode: number | null = null
    private getbrbpCode() {
        this.brbpCode = null;
        if (this.brbpWrapper.brbp && this.brbpWrapper.brbp.id && this.brbpWrapper.brbp.id !== this.brbpCode) this.brbpCode = this.brbpWrapper.brbp.id;
    }

    private errors: any[] = [];
    private get canMoveForward() {
        const errorsDict:any = {
            conclNpaCode: "Оценка соответствия бюджетной заявки бюджетному и иному законодательству РК",
            conclDescrCode: "Оценка соответствия показателей бюджетной программы функциям, полномочиям, направлениям деятельности АБП",
            conclFrCode: "Оценка соответствия бюджетной заявки ПСЭР, ПРТ области, действующим натуральным нормам",
            conclExpendCode: "Предложения УО по экономике и бюджетного планированию",
            conclDescrInflCode: "Заключение (ППР)",
            conclSeeCode: "Заключение (Эффект)",
            conclPeeCode: "Заключение (Расходы)"
        }
        let result: boolean = true;
        if (this.brbpWrapper && this.brbpWrapper.brbp) {
            this.errors.splice(0)
            for (const [i, [key, value]] of Object.entries(Object.entries(errorsDict))) {
                if (parseInt(i) < 4) {
                    if (this.conclusions[key] && this.conclusions[key] === "1") {
                        result = result && true;
                    } else {
                        result = result && false;
                        this.errors.push(value)
                    }
                } else {
                    if (this.brbpWrapper.pprData && this.brbpWrapper.pprData.length) {
                        this.brbpWrapper.pprData.forEach((ppr: any) => {
                            if (ppr.brbp && ppr.brbp[key] && ppr.brbp[key] === "1") {
                                result = result && true;
                            } else {
                                result = result && false;
                                this.errors.push(`БПП-${ppr.brbp.ppr}. ${value}`)
                            }
                        })
                    }
                }

            }
        }
        return result;
    }

    private get getSigner() {
        if (!this.conclDict) return null;
        const currStep = this.conclDict.filter((item:any) => item.code === this.aggrLevelCode)[0];
        if (!currStep || !currStep.operation.length) return null;
        return currStep.operation[0].code;
    }

    private get disableConclBtns() {
        if (!this.bpMode && this.getSigner === 'abp_sign') return false
        if (!this.bpMode && this.getSigner === 'uebp_agree') return true
        if (this.bpMode && this.getSigner === 'uebp_agree') return false
        if (this.bpMode && this.getSigner === 'abp_sign') return true
        return false
    }
    private get aggrLevelCode() {
        if (this.aggHistory.length) {
            // this.aggHistory.sort((a: any, b:any) => {
            //     return b.updateTime - a.updateTime
            // })
            return parseInt(this.aggHistory[this.aggHistory.length - 1].approveStatus)
        }
        return 1
    };
    private set aggrLevelCode(value: number) {}

    private showConcl: boolean = true;

    //получение наименования текущего шага
    private getCurrStep: string | null = null;
    private setCurrStep() {
        if (!this.agrCodesDict.length) return this.getCurrStep = null; 
        this.getCurrStep = this.agrCodesDict.filter(curr => curr.code === this.aggrLevelCode)[0][this.fieldLocalName]
    }

    private setCurrStepinTable() {
        if (this.tableFieldsItems.length && this.brbpCode && this.aggrLevelCode) {
            this.tableFieldsItems = this.tableFieldsItems.map((item: any) => {
                if (item.bpId === this.brbpCode) {
                        this.$set(item, 'approveStatus', this.aggrLevelCode.toString())
                    }

                return item
            })
        }
    }

    // получение локализованного имени поля
    private get fieldLocalName() {
        return this.$i18n.locale === 'kk' ? 'name_kk' : 'name_ru';
    }

    private getStateName(code: string) {
        if (!this.agrCodesDict.length) return; 
        const newCode: string = code || "1";
        const currStep = this.agrCodesDict.filter((item: any) => item.code === parseInt(newCode))[0]
        if (!currStep) return;
        return `${currStep.code} - ${currStep[this.fieldLocalName]}`
    }


    // блокировка разделов в зависимости от решения

    private get blockNpa() {
        if (this.onlyReadMode) return 'budgetprogramdisabled';
        if (this.conclusions.conclNpaCode === "1") return 'budgetprogramdisabled';
        return null
    }

    private get blockDescr() {
        if (this.onlyReadMode) return 'budgetprogramdisabled';
        if (this.conclusions.conclDescrCode === "1") return 'budgetprogramdisabled';
        return null
    }

    private get blockFr() {
        if (this.onlyReadMode) return 'budgetprogramdisabled';
        if (this.conclusions.conclFrCode === "1") return 'budgetprogramdisabled';
        return null
    }

    private get blockRegionCheckbox() {
        if (this.onlyReadMode) return 'budgetprogramdisabled';
        if (this.brbpPprSelected.brbp && (this.brbpPprSelected.brbp.conclDescrInflCode === "1" || this.brbpPprSelected.brbp.conclSeeCode === "1" || this.brbpPprSelected.brbp.conclPeeCode === "1")) return 'budgetprogramdisabled';
        return null
    }

    private onBPModalClick(evnt: any) {
        if (evnt.target.classList.contains("custom-control-label") || evnt.target.parentElement.classList.contains("custom-control-label")) {
            this.onBPModalOk(evnt);
        }
    } // создание программы по двойному щелчку
}
