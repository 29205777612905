





































































































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import { BrbpPprWrapper } from "@/modules/budget-request/components/js/brbp-wrapper";
import { BrbpPee } from '@/modules/budget-request/components/js/pee';
import BudgerRequestMixin from '../mixins/bp-mixin';

@Component({
    name: 'c-table-budget-costs',
    filters: {
        addRanks: function (value: any) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        }
    }
})
export default class CTableBudgetCosts extends mixins(BudgerRequestMixin)  {
    @Prop({
        required: true,
        default: {}
    })
    private filter!: any;

    @Prop({
        type: [],
        required: true
    })
    private data!: BrbpPprWrapper;

    @Prop({
        required: true,
    })
    private itputState!: string | null;

    @Prop({
        required: true,
    })
    private conclState!: string | null;

    @Prop({
        required: true
    })
    private getCellText!: Function;

    @Prop({
        required: true
    })
    private keyPress!: Function;
     
    @Prop({
        required: true
    })
    private parseVal!: Function;

    @Prop({
        required: true,
        default: false
    })
    private ifRegion!: boolean;
    
    @Prop({
        required: true,
        default: false
    })
    private curBpState!: boolean;

    @Prop({
        required: true
    })
    private toggleChilds!: Function;

    @Prop({
        required: true
    })
    private addBibObjectIntoTable!: Function;

    @Prop({
        required: true
    })
    private costTopOffset!: string;

    @Prop({
        required: true
    })
    private setOffset!: Function;

    @Prop({
        required: true
    })
    private localName!: Function;

    @Prop({
        required: true
    })
    private conclAttributes1!: any[];

    private created() {
        this.$watch('data.driData', this.prepareTabledata, { deep: true });
        this.$watch('filter.pgr.ppr', this.prepareTabledata, { deep: true });
        this.$watch('ifRegion', this.prepareTabledata) //this.forceToggleOpenAll);
        this.prepareTabledata();
    }
    // =============== приведение СЭЭ в соответствие с ППР ============
    private prepareTabledata() {
        this.hierarchyData = [];
        this.isAllOpen = false;
        if (this.data.driData && this.data.peeData && this.data.driData.length > 0) {
            const peeData = JSON.parse(JSON.stringify(this.data.peeData));
            const driData = JSON.parse(JSON.stringify(this.data.driData));
            let peeRecordBase = new BrbpPee();
            driData.forEach((dri: any) => {
                if (peeData.findIndex((item: any) => {
                    return this.comparePrjOrBip(item, dri, this.data, this.curBpState);
                }
                ) === -1) {
                    peeRecordBase = {
                        id: Date.now() + Math.floor(Math.random() * 998) + 1,
                        region: dri.region,
                        bip: dri.bip ? dri.bip : undefined,
                        project: dri.project ? dri.project : undefined,
                        expendReportYear: undefined,
                        expendCorrectPlan: undefined,
                        expendPlanPeriod1: undefined,
                        expendPlanPeriod2: undefined,
                        expendPlanPeriod3: undefined
                    };
                    peeData.push(peeRecordBase);
                }
            });
            peeData.forEach((pee: any, i: number) => {
                const rowIndex = driData.findIndex((item: any) => {
                    return this.comparePrjOrBip(item, pee, this.data, this.curBpState);
                }

                );
                if (rowIndex === -1) {
                    peeData.splice(i, 1);
                }
            });

            if (peeData.length > 0) {
                this.data.peeData = peeData;
            }
            if (peeData.length > 0) {
                this.createHierarchyTable(peeData);
            }
        }
    }

    // private comparePrjOrBip(item1: any, item2: any, tex: string) {
    //     if (!this.curBpState) {
    //         let res = item1.region === item2.region;
    //         if (item1.project && item2.project) {
    //             res = res && item1.project.id === item2.project.id;
    //         }
    //         if (!item1.project && !item2.project) {
    //             return false;
    //         }
    //         if ((!item1.project && item2.project) || (item1.project && !item2.project)) {
    //             return false;
    //         }
    //         return res;
    //     }

    //     return item1.bip === item2.bip;
    // }

    private hierarchyData: any[] = [];
    private unicRegions: any[] = [];
    private createHierarchyTable(peeData: any[]) {
        const newPeeData = this.addBibObjectIntoTable(JSON.parse(JSON.stringify(peeData)));
        this.unicRegions = [];
        newPeeData.forEach((pee: any) => {
            if (this.unicRegions.indexOf(pee.region) === -1) {
                this.unicRegions.push(pee.region);
            }
            pee.type = 2;
            pee.collapsed = this.ifRegion;
            pee.open = false;
        });
        if (!this.curBpState) {
            this.unicRegions.forEach((region: string) => {
                newPeeData.push({ region, type: 1, collapsed: false, open: false });
            });
        }

        const sortByBipOrProject = (a: any, b: any) => {
            let aa = a.project;
            let bb = b.project;
            if (!this.curBpState) {
                aa = a.bip;
                bb = b.bip;
            }
            let prj = 1;
            if (!!aa && !!bb) {
                prj = (bb.id < aa.id ? 1 : 0) - (aa.id < bb.id ? 1 : 0);
            }
            if (!aa && !!bb) {
                prj = 0;
            }
            if (!!aa && !bb) {
                prj = -1;
            }
            if (!aa && !!bb) {
                prj = 1;
            }
            return prj;
        };
        newPeeData.sort((a: any, b: any) => {
            let res = 0;
            const prj = sortByBipOrProject(a, b);

            let reg = 1;
            if (a.region && b.region) {
                reg = (parseInt(b.region) < parseInt(a.region) ? 1 : 0) - (parseInt(a.region) < parseInt(b.region) ? 1 : 0);
            }
            if (!a.region && !b.region) {
                reg = 0;
            }
            if (!a.region && b.region) {
                reg = 1;
            }
            if (a.region && !b.region) {
                reg = -1;
            }
            if (!this.curBpState) {
                res = (
                    reg
                    || (b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0)
                    || prj
                );
            } else {
                res = ((b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0)|| prj);
            }
            return res;
        });
            // (parseInt(b.region) < parseInt(a.region) ? 1 : 0) - (parseInt(a.region) < parseInt(b.region) ? 1 : 0)
            //                             || (b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0)
            //                             || sortByBipOrProject(a, b));
        // adding row number
        let l1 = 0;
        let l2 = 0;
        newPeeData.forEach((item: any) => {
            if (item.type === 1) {
                ++l1;
                if (this.ifRegion) {
                    l2 = 0;
                    item.rowNumber = String(l1);
                }
                item.id = l1 - 10000;
            } else {
                ++l2;
                item.rowNumber = this.ifRegion ? `${l1}.${l2}` : String(l2);
            }
        });
        this.hierarchyData = newPeeData;
        this.prepareTotals();
    }

    // посчет итогов
    private totals: any = {
        reportYear: 0,
        correctPlan: 0,
        planPeriod1: 0,
        planPeriod2: 0,
        planPeriod3: 0
    };
    private totalsByRegion: any[] = [];
    private prepareTotals() {
        // подсчет итогов
        this.totals.reportYear = 0;
        this.totals.correctPlan = 0;
        this.totals.planPeriod1 = 0;
        this.totals.planPeriod2 = 0;
        this.totals.planPeriod3 = 0;
        if (this.data.peeData.length > 0) {
            this.data.peeData.forEach((rec: any) => {
                if (rec.expendReportYear) {
                    this.totals.reportYear += Number(rec.expendReportYear);
                }
                if (rec.expendCorrectPlan) {
                    this.totals.correctPlan += Number(rec.expendCorrectPlan);
                }
                if (rec.expendPlanPeriod1) {
                    this.totals.planPeriod1 += Number(rec.expendPlanPeriod1);
                }
                if (rec.expendPlanPeriod2) {
                    this.totals.planPeriod2 += Number(rec.expendPlanPeriod2);
                }
                if (rec.expendPlanPeriod3) {
                    this.totals.planPeriod3 += Number(rec.expendPlanPeriod3);
                }
            });
        }

        // подсчет промежуточных итогов для регионов
        this.totalsByRegion = [];
        if (this.unicRegions.length > 0 && this.hierarchyData.length > 0) {
            this.unicRegions.forEach((reg: string) => {
                const newData = this.data.peeData.filter((item: any) => item.hasOwnProperty('region') && item.region === reg);
                const newRegTotal = {
                    region: null,
                    reportYear: 0,
                    correctPlan: 0,
                    planPeriod1: 0,
                    planPeriod2: 0,
                    planPeriod3: 0
                };
                newData.forEach((rec: any) => {
                    newRegTotal.region = rec.region;
                    if (rec.expendReportYear) {
                        newRegTotal.reportYear += Number(rec.expendReportYear);
                    }
                    if (rec.expendCorrectPlan) {
                        newRegTotal.correctPlan += Number(rec.expendCorrectPlan);
                    }
                    if (rec.expendPlanPeriod1) {
                        newRegTotal.planPeriod1 += Number(rec.expendPlanPeriod1);
                    }
                    if (rec.expendPlanPeriod2) {
                        newRegTotal.planPeriod2 += Number(rec.expendPlanPeriod2);
                    }
                    if (rec.expendPlanPeriod3) {
                        newRegTotal.planPeriod3 += Number(rec.expendPlanPeriod3);
                    }
                });
                this.totalsByRegion.push(newRegTotal);
            });
        }
    }
    public setNameLang(obj: any) {
        if (obj) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            return txt;
        }
        return obj;
    }
    private toggleChl(item: any, type: number, arr: any) {
        this.toggleChilds(item, type, arr);
        if (this.isAllOpen) {
            this.isAllOpen = false;
        }
    }
    private forceToggleOpenAll() {
        this.isAllOpen = this.ifRegion;
        this.openAll();
    }
    private isAllOpen = false;
    private openAll() {
        this.setOffset()
        this.isAllOpen = !this.isAllOpen;
        this.hierarchyData.map((item: any) => {
            item.open = this.isAllOpen;
            if (item.type !== 1) {
                item.collapsed = !this.isAllOpen;
            }
            return item;
        });
    }

    // отображение промежуточных итогов
    private getTotalByRegion(region: string, column: string) {
        if (this.totalsByRegion.length > 0 && region) {
            const regionTotals = this.totalsByRegion.filter((item: any) => item.region === region);
            return regionTotals[0][column];
        }
        return 0;
    }

    // запись данных в таблицу pee при изменении
    private onChangeData(e: any, row: any, column: keyof BrbpPee) {
        if (column !== 'noteRu') {        
            e = this.parseVal(e);
            row[column] = e;
        }
        this.data.peeData.filter((item: any) => item.id === row.id)[0][column] = column !== 'noteRu' ? Number(e) : e;
        this.prepareTotals();
        this.$emit('compareCostTotals');
        this.$emit('handlingChange');
    }

    // блокировка разделов в зависимости от решения

    private get blockPee() {
        if (this.data.brbp && this.data.brbp.conclPeeCode === "1") return 'budgetprogramdisabled';
        return null
    }
}
