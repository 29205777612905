/**
 * kz.csi.stat.csc.modules.budget.request.bp.data.BudgetRequestBpSeeDto
 */
export class BrbpSee {
    id?: number;
    budgetRequestBp?: number;
    region?: string;
    bip?: string;
    project?: number | any;
    see: number | any;
    effReportYear?: number;
    effCorrectPlan?: number;
    effPlanPeriod1?: number;
    effPlanPeriod2?: number;
    effPlanPeriod3?: number;
    noteRu?: string;

    public static clone(source: BrbpSee): BrbpSee {
        const res = new BrbpSee();
        res.id = source.id;
        res.budgetRequestBp = source.budgetRequestBp;
        res.region = source.region;
        res.bip = source.bip;
        res.project = source.project;
        res.see = source.see;
        res.effReportYear = source.effReportYear;
        res.effCorrectPlan = source.effCorrectPlan;
        res.effPlanPeriod1 = source.effPlanPeriod1;
        res.effPlanPeriod2 = source.effPlanPeriod2;
        res.effPlanPeriod3 = source.effPlanPeriod3;
        res.noteRu = source.noteRu;
        return res;
    }
}