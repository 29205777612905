

































































































































































































































































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import { BrbpPprWrapper } from "@/modules/budget-request/components/js/brbp-wrapper";
import { BrbpSee } from '@/modules/budget-request/components/js/see';
import HintList from './table-dri-hint-list.vue';
import { findIndex } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import BudgerRequestMixin from '../mixins/bp-mixin';

@Component({
    name: 'c-table-budget-effect',
    components: {
        'table-dri-hint-list': HintList
    },
    filters: {
        addRanks: function (value: any) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        }
    }
})
export default class CTableBudgetEffect extends mixins(BudgerRequestMixin) {

    @Prop({
        required: true,
        default: {}
    })
    private filter!: any;

    @Prop({
        type: [],
        required: true
    })
    private data!: BrbpPprWrapper;

    @Prop({
        required: false,
        default: false
    })
    private bpMode!: boolean;

    @Prop({
        required: true,
    })
    private itputState!: string | null;
    
    @Prop({
        required: true,
    })
    private conclState!: string | null;
    
    @Prop({
        required: true
    })
    private ifRegion!: boolean;

    @Prop({
        required: true
    })
    private localName!: Function;

    @Prop({
        required: true
    })
    private conclAttributes1!: any[];

    @Prop({
        required: true
    })
    private curBpState!: boolean;

    @Prop({
        required: true
    })
    private getCellText!: Function;

    @Prop({
        required: true
    })
    private addRowNumber!: Function;

    @Prop({
        required: true
    })
    private scrollIntoVw!: Function;

    @Prop({
        required: true
    })
    private makeToast!: Function;

    @Prop({
        required: true
    })
    private delDictItem!: Function;

    @Prop({
        required: true
    })
    private onEditDictItem!: Function;

    @Prop({
        required: true
    })
    private toggleChilds!: Function;

    @Prop({
        required: true
    })
    private addBibObjectIntoTable!: Function;

    @Prop({
        required: true
    })
    private getUnitName!: Function;

    @Prop({
        required: true
    })
    private saveDictItem!: Function;

    @Prop({
        required: true
    })
    private keyPress!: Function;
     
    @Prop({
        required: true
    })
    private parseVal!: Function;

    @Prop({
        required: true,
        default: []
    })
    private dictSee!: any[];

    @Prop({
        required: true,
        default: []
    })
    private units!: any[];

    @Prop({
        required: true
    })
    private effTopOffset!: string;

    @Prop({
        required: true
    })
    private setOffset!: Function;
    
    private created() {
        this.$watch('data.driData', this.prepareTabledata, { deep: true });
        this.$watch('filter.pgr.ppr', this.prepareTabledata, { deep: true });
        this.$watch('ifRegion', this.prepareTabledata)
        this.$watch('effTopOffset', this.setSecondRowOffset);
        this.prepareTabledata();
    }

    private secondRowOffset: string | null = null;
    private setSecondRowOffset() {
        if (this.effTopOffset !== 'auto') {
            this.secondRowOffset = +this.effTopOffset.substring(0, this.effTopOffset.length - 3) + 46 + 'px';
        }
    }
    private prepareTabledata() {
        this.hierarchyData = [];
        this.isAllOpen = false;
        if (this.data.driData && this.data.seeData && this.data.driData.length > 0) {
            let seeData = JSON.parse(JSON.stringify(this.data.seeData));
            const driData = JSON.parse(JSON.stringify(this.data.driData));
            let seeRecordBase = new BrbpSee();
            driData.forEach((dri: any) => {
                if (seeData.findIndex((item: any) => {
                    return this.comparePrjOrBip(item, dri, this.data, this.curBpState);
                }
                ) === -1) {
                    seeRecordBase = {
                        id: Date.now() + Math.floor(Math.random() * 998) + 1,
                        region: dri.region,
                        bip: dri.bip ? dri.bip : undefined,
                        project: dri.project ? dri.project : undefined,
                        see: undefined,
                        effReportYear: undefined,
                        effCorrectPlan: undefined,
                        effPlanPeriod1: undefined,
                        effPlanPeriod2: undefined,
                        effPlanPeriod3: undefined
                    };
                    seeData.push(seeRecordBase);
                }
            });
            const newSeeData: any = [];
            seeData.forEach((see: any, i: number) => {
                const rowIndex = driData.findIndex((item: any) => {
                    return this.comparePrjOrBip(item, see, this.data, this.curBpState);
                });
                if (rowIndex !== -1) {
                    newSeeData.push(see);
                }
            });
            seeData = newSeeData;
            if (seeData.length > 0) {
                this.data.seeData = seeData;
            }
            if (seeData.length > 0) {
               this.hierarchyTablePrepare(seeData);
            }
            this.prepareSeeData();
        }
    }

    // private comparePrjOrBip(item1: any, item2: any, data: any, curBpState: boolean) {
    //     if (!this.curBpState) {
    //         if (!item2.project) {
    //             console.log(item1, item2)
    //         }
    //         let res = true;
    //         if (data.brbp && data.brbp.driByRegion === true) {
    //             res = item1.region === item2.region;
    //         }
    //         if (item1.project && item2.project) {
    //             res = res && item1.project.id === item2.project.id;
    //         }
    //         if (!item1.project === null  && !item2.project) {
    //             res = res && true;
    //         }
    //         if ((!item1.project && item2.project) || (item1.project && item1.project !== null && !item2.project)) {
    //             return false;
    //         }
    //         console.log(res)
    //         return res;
    //     }

    //     return item1.bip === item2.bip;
    // }

    private hierarchyData: any[] = [];
    private isAllOpen = false;
    private hierarchyTablePrepare(seeData: any[]) {
        this.isAllOpen = false;
        this.hierarchyData.length = 0;
        const seeProjects: any[] = [];
        const seeRegions: any[] = [];
        const seeRecords: any[] = [];
        const newSeeData = this.addBibObjectIntoTable(JSON.parse(JSON.stringify(seeData)));
        const pushRecord = (recType: string, id: number, region: string, project: any, type: number) => {
            const data = {
                id,
                region,
                project,
                type,
                open: false,
                collapsed: this.ifRegion ? type === 2 : false
            };
            if (recType === 'project') {
                seeProjects.push(data);
            }
            if (recType === 'region') {
                seeRegions.push(data);
            }
        };
        newSeeData.forEach((see: any, i: number) => {
            seeRecords.push({
                ...see,
                type: 3,
                open: false,
                collapsed: true
            });

            if (seeRegions.length > 0 && !this.curBpState) {
                let isDouble = false;
                for (const item of seeRegions) {
                    if (item.region === see.region) {
                        isDouble = true;
                    }
                }
                if (!isDouble) {
                    pushRecord('region', i - 10000000, see.region, { id: i - 10000 }, 1);
                }
            } else {
                pushRecord('region', i - 10000000, see.region, { id: i - 10000 }, 1);
            }

            if (seeProjects.length > 0) {
                let isDouble = false;
                for (const item of seeProjects) {
                    if (item.region === see.region && item.project && see.project && item.project.id === see.project.id) {
                        isDouble = true;
                    }
                    if (this.curBpState && item.project && see.project && item.project.id === see.project.id) {
                        isDouble = true;
                    }
                    if (!this.curBpState && item.project === see.project) {
                        isDouble = true;
                    }
                }
                if (!isDouble) {
                    pushRecord('project', i - 10000, see.region, see.project, 2);
                }
            } else {
                pushRecord('project', i - 10000, see.region, see.project, 2);
            }
        });
        this.hierarchyData = [...seeRegions, ...seeProjects, ...seeRecords]
        this.hierarchyData.sort((a: any, b: any) => {
            let res = 0;
            let prj = 1;
            if (!!a.project && !!b.project) {
                prj = (b.project.id < a.project.id ? 1 : 0) - (a.project.id < b.project.id ? 1 : 0);
            }
            if (!a.project && !!b.project) {
                prj = 0;
            }
            if (!!a.project && !b.project) {
                prj = -1;
            }
            if (!a.project && !!b.project) {
                prj = 1;
            }

            let reg = 1;
            if (a.region && b.region) {
                reg = (parseInt(b.region) < parseInt(a.region) ? 1 : 0) - (parseInt(a.region) < parseInt(b.region) ? 1 : 0);
            }
            if (!a.region && !b.region) {
                reg = 0;
            }
            if (!a.region && b.region) {
                reg = 1;
            }
            if (a.region && !b.region) {
                reg = -1;
            }
            if (!this.curBpState) {
                res = (
                    reg
                    || prj
                    || (b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0)
                );
            } else {
                res = (prj || (b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0));
            }
            return res;
        });
        this.addRowNumber(this.hierarchyData);
    }

    public setNameLang(obj: any) {
        if (obj) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            return txt;
        }
        return obj;
    }

    private openAll() {
        this.setOffset();
        this.isAllOpen = !this.isAllOpen;
        if (!this.isAllOpen) {
            this.hierarchyData.forEach((row: any) => {
                if (row.type === 1 && this.ifRegion) {
                    row.collapsed = false;
                    row.open = false;
                } else if (row.type === 2 && !this.ifRegion) {
                    row.collapsed = false;
                    row.open = false;
                } else {
                    row.collapsed = true;
                    row.open = false;
                }
            });
        } else {
            this.hierarchyData.forEach((row: any) => {
                row.collapsed = false;
                row.open = true;
            });
        }
    }

    private searchLine = '';
    private selectedItem: any | null = null;
    private modalAppendDictItem: any = { show: false };
    private modalDataIsSavable = false;
    private modalAppendDictItemUnit: any | null = null;

    private onOpenModal(dt: any, type: string) {
        this.searchLine = '';
        this.editedRow = dt;
        this.selectedItem = dt[type];
        this.modalAppendDictItem.editCol = type;
        this.scrollIntoVw(this.filteredSee, this.selectedItem, 'cardmodalsee');
    }

    // ============ очистка данных поиска в модальном окне ======
    private onResetModal() {
        this.searchLine = '';
        this.onSearch();
    }

    private filteredSee: any[] = [];
    private prepareSeeData() {
        this.filteredSee.length = 0;
        this.dictSee.forEach((item: any) => {
            this.filteredSee.push({ ...item, filtered: false, value: item });
        });
        this.filteredSee.sort((a: any, b: any) => b.id - a.id);
    }

    private onSearch() {
        const fieldName = this.$i18n.locale === 'en' ? 'name_ru' : 'name_' + this.$i18n.locale;
        this.filteredSee = this.filteredSee.map((item: any) => {
            if (item[fieldName].toLowerCase().indexOf(this.searchLine.toLowerCase()) === -1) {
                return { ...item, filtered: true };
            }
            return { ...item, filtered: false };
        });
    }

    private onChangeRecord(value: string) {
        this.$nextTick(() => {
            this.$set(this, 'modalDataIsSavable', this.modalAppendDictItem.name_ru.length > 0 && !!this.modalAppendDictItemUnit);
        });
    }
    // добавление в справочник 
    private async appendDictItem() {
        if (!this.nameValidation(this.modalAppendDictItem.name_kk, this.modalAppendDictItem.name_ru)) {
            return;
        }

        this.modalAppendDictItem.show = false;

        const savedDictItem = {
            name_kk: this.modalAppendDictItem.name_kk.trim(),
            name_ru: this.modalAppendDictItem.name_ru.trim(),
            unit: this.modalAppendDictItemUnit.code
        };
        const dict = 'bp_see';
        const response = await this.saveDictItem(dict, savedDictItem);
        if (response.status === 200) {
            const newDictItem = await response.json();
            this.searchLine = '';
            if (newDictItem !== null) {
                this.dictSee.push(newDictItem);
                this.selectedItem = newDictItem;
                this.prepareSeeData();
            }
            this.modalAppendDictItem.show = false;
            this.makeToast('success', 'Добавление', 'Запись успешно добавлена');
            this.resetHintsList();
        } else {
            this.makeToast('warning', 'Ошибка сохранения нового элемента', `${response.status} - ${response.statusText}`);
        }
    }

    // ======== поиск похожих наименований =======
    private relativeItemsListRu: any[] = [];
    private relativeItemsListKk: any[] = [];
    private whichHintShow: null | string = null;
    private resetHintsList() {
        this.relativeItemsListRu.length = 0;
        this.relativeItemsListKk.length = 0;
        this.whichHintShow = null;
    }

    // проверка существования записи в справочнике
    private nameValidation(nameKk: string, nameRu: string) {
        if (!this.modalAppendDictItemUnit) {
            this.makeToast('danger', 'Ошибка сохранения', 'Не выбрана единица измерения');
            return false;
        }
        nameKk = nameKk.trim();
        nameRu = nameRu.trim();
        if (nameRu) {
            const ruIndex = this.dictSee.findIndex((item: any) => item.name_ru.toLowerCase() === nameRu.toLowerCase());
            const kkIndex = nameKk ? this.dictSee.findIndex((item: any) => item.name_kk === nameKk) : -1;
            if (ruIndex !== -1 || kkIndex !== -1) {
                this.makeToast('danger', 'Ошибка сохранения', `Запись с таким наименованием на русском ${kkIndex !== -1 ? 'и казахском языках' : 'языке'} уже существует`);
                return false;
            }
        } else {
            this.makeToast('danger', 'Ошибка сохранения', 'Наименование не должно быть пустым');
            return false;
        }
        return true;
    }

    // добавление эффекта в таблицу
    private onRecordChange(e: any) {
        if (this.areThereDoubles()) {
            e.preventDefault();
            return;
        }

        this.hierarchyData = this.hierarchyData.map((item: any) => {
            if (item.id === this.editedRow.id) {
                if (this.selectedItem) {
                    return { ...item, see: this.selectedItem };
                }
            }
            return item;
        });
        this.data.seeData = this.data.seeData.map((item: any) => {
            if (item.id === this.editedRow.id) {
                if (this.selectedItem) {
                    return { ...item, see: this.selectedItem };
                }
            }
            return item;
        });
        this.selectedItem = null;
        this.$bvModal.hide('edit-eff-modal');
        this.$emit('handlingChange');
    }

    private editedRow: any | null = null;

    // поиск дублей в таблице 
    private areThereDoubles() {
        let flag = false;
        this.hierarchyData.forEach((see: any) => {
            if (see.id !== this.editedRow.id) {
                if (see.see
                    && see.see.id
                    && this.selectedItem
                    && this.selectedItem.id
                    && see.see.id === this.selectedItem.id) {
                    if (see.project
                        && see.project.id
                        && this.editedRow.project
                        && this.editedRow.project.id
                        && see.project.id === this.editedRow.project.id) {
                        if (see.region && this.editedRow.region && see.region === this.editedRow.region) {
                            flag = true;
                        }
                        if (!see.region && !this.editedRow.region) {
                            flag = true;
                        }

                    }
                }
            }
        });

        if (flag) {
            
            this.selectedItem = null;
            this.makeToast('danger', 'Ошибка', 'Комбинация Город/Район/МСУ + Проект/Мероприятие + Эффект уже существует');
        }
        return flag;
    }

    // добавление новой записи
    private addRow(ind?: number, prevRow?: any) {
        const newId = Date.now();
        const newRow = {
            id: newId,
            project: prevRow.project,
            region: prevRow.region,
            type: 3,
            open: true,
            collapsed: false
        };

        if (ind === 0 || ind) {
            const newInd = this.hierarchyData.findIndex((item: any) => item.id === prevRow.id);
            this.hierarchyData.splice(newInd + 1, 0, newRow);
        } else {
            this.hierarchyData.push(newRow);
        }
        this.addRowNumber(this.hierarchyData);
        if (this.data && this.data.seeData) {
            const newBrbpSee = new BrbpSee();
            newBrbpSee.id = newId;
            newBrbpSee.project = prevRow.project;
            newBrbpSee.region = prevRow.region;
            if (ind === 0 || ind) {
                this.data.seeData.splice(ind + 1, 0, newBrbpSee);
            } else {
                this.data.seeData = [newBrbpSee];
            }
        }
    }

    // удаление строки таблицы
    private removeRow(row: any) {
        if (this.data && this.data.seeData) {
            const index = this.data.seeData.findIndex((item: any) => item.id === row.id);
            this.data.seeData.splice(index, 1);
            const hindex = this.hierarchyData.findIndex((item: any) => item.id === row.id);
            this.hierarchyData.splice(hindex, 1);
        }
        if (this.data.seeData.isEmpty) {
            this.hierarchyData.length = 0;
            this.addRow();
        }
        this.addRowNumber(this.hierarchyData);
    }

    // удаление из справочника программы или показателя
    private async onDeleteItem() {
        const dict = 'bp_see';
        const recordId = this.selectedItem.id;

        const response = await this.delDictItem(dict, recordId);
        if (response.status === 200) {
            const delId = this.dictSee.findIndex((elem: any) => elem.id === recordId);
            this.dictSee.splice(delId, 1);
            this.prepareSeeData();
            this.selectedItem = null;
            this.makeToast('success', 'Удаление', 'Запись успешно удалена');
        } else if (response.status === 409) {
            this.makeToast('warning', 'Ошибка удаления', 'Выбранный Эффект невозможно удалить, т.к. он используется в описаниях других мероприятий');
        } else {
            this.makeToast('danger', 'Ошибка удаления', `${response.status} - ${response.statusText}`);
        }
    }

    // очистка инпутов в окне добавления записи в справочник
    private resetFields() {
        this.modalAppendDictItem.name_kk = '';
        this.modalAppendDictItem.name_ru = '';
        this.modalAppendDictItemUnit = null;
        this.modalDataIsSavable = false;
    }

    // ============= заполнение полей в модалке при редактировании
    private editedNameRu = '';
    private editedNameKk = '';
    private fillFields() {
        this.editedNameKk = this.selectedItem.name_kk;
        this.editedNameRu = this.selectedItem.name_ru;
        this.modalAppendDictItemUnit = this.units.filter((unit: any) => unit.code === this.selectedItem.unit)[0];
        this.modalDataIsSavable = false;
    }

    // поиск существующих значений в справочнике программ или индикаторов
    private popUpListGenerator(e: any, lang: string, action: string) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
        const allItemsList = this.filteredSee;
        const atrName = 'name_' + lang;
        let substrText = '';
        if (action === 'delete') {
            substrText = this.modalAppendDictItem[atrName];
        }
        if (action === 'edit') {
            if (lang === 'ru') {
                substrText = this.editedNameRu;
            }
            if (lang === 'kk') {
                substrText = this.editedNameKk;
            }
        }

        const arrItems: any[] = [];
        for (const item of allItemsList) {
            const strText = item[atrName];
            if (substrText && strText && strText.toLowerCase().indexOf(substrText.toLowerCase()) === 0) {
                arrItems.push(strText);
            }
            if (arrItems.length === 10) {
                break;
            }
        }
        if (lang === 'ru') {
            this.relativeItemsListRu = arrItems;
        }
        if (lang === 'kk') {
            this.relativeItemsListKk = arrItems;
        }
    }

    // ============= редактирование
    private async onRecordEdit(e: any) {
        e.preventDefault();
        if (!this.nameValidation(this.editedNameKk, this.editedNameRu)) {
            return;
        }
        const dictItem = { ...this.selectedItem,
            name_ru: this.editedNameRu.trim(),
            name_kk: this.editedNameKk.trim(),
            unit: this.modalAppendDictItemUnit ? this.modalAppendDictItemUnit.code : null };
        const dict = 'bp_see';

        const response = await this.onEditDictItem(dict, dictItem);
        if (response.status === 200) {
            const newDictItem = await response.json();
            if (newDictItem !== null) {
                const itemIndex = this.dictSee.findIndex((item: any) => item.id === newDictItem.id);
                this.dictSee.splice(itemIndex, 1, newDictItem);
                this.selectedItem = newDictItem;
                this.prepareSeeData();
                // this.data.seeData[this.editRowIndex].indicator = newDictItem;
        
                this.$root.$emit('bv::hide::modal', 'modal-edit-eff-item');
                this.modalAppendDictItem.show = false;
                this.makeToast('success', 'Изменение', 'Запись успешно изменена');
                this.resetHintsList();
            }
        } else {
            this.makeToast('warning', 'Ошибка сохранения нового элемента', `${response.status} - ${response.statusText}`);
        }
    }

    // запись данных в таблицу pee при изменении
    private onChangeData(e: any, row: any, column: keyof BrbpSee) {
        if (column !== 'noteRu') {
            e = this.parseVal(e);
            row[column] = e;
        }
        this.data.seeData.filter((item: any) => item.id === row.id)[0][column] = column !== 'noteRu' ? Number(e) : e;
        this.$emit('handlingChange');
    }

    // блокировка разделов в зависимости от решения
    private get blockSee() {
        if (this.data.brbp && this.data.brbp.conclSeeCode === "1") return 'budgetprogramdisabled';
        return null
    }

    private onModaldbClick(evnt: any) {
        if (evnt.target.classList.contains("custom-control-label") || evnt.target.parentElement.classList.contains("custom-control-label")) {
            this.onRecordChange(evnt);
        }
    } // создание программы по двойному щелчку
}
