import { Component, Vue } from "vue-property-decorator";

@Component
export default class BudgerRequestMixin extends Vue {
    public comparePrjOrBip(item1: any, item2: any, data: any, curBpState: boolean, mark?: string) {
        if (!curBpState) {
            if (!item2.project) {
                // console.log(item1, item2)
            }
            let res = true;
            if (data.brbp && data.brbp.driByRegion === true) {
                res = item1.region === item2.region;
            }
            if (item1.project && item2.project) {
                res = res && item1.project.id === item2.project.id;
            }
            if (!item1.project === null  && !item2.project) {
                res = res && true;
            }
            if ((!item1.project && item2.project) || (item1.project && item1.project !== null && !item2.project)) {
                return false;
            }
            // console.log(res)
            return res;
        }

        return item1.bip === item2.bip;
    }
}