/**
 * kz.csi.stat.csc.modules.budget.request.bp.data.BudgetRequestBpRec
 */
export class Brbp {
    id?: number;
    planYear: number;
    region: string;
    typeBpLevel?: number;
    typeBpContent?: number;
    typeBpImpl?: number;
    resultOther?: string;
    descrRu?: string;
    descrKk?: string;
    goalOtherRu?: string;
    goalOtherKk?: string;
    goal?: number;
    abp?: number;
    prg?: number;
    ppr: number | null;
    driByRegion?: boolean;
    driByProject?: boolean;
    textGoalFr?: any;
    conclNpaRu?: string;
    conclBpTypeRu?: string;
    conclDescrRu?: string;
    conclGoalRu?: string;
    conclFrRu?: string;
    conclExpendRu?: string;
    conclDescrInflRu?: string;
    conclDriRu?: string;
    conclCommonRu?: string;
    conclSeeRu?: string;
    conclPeeRu?: string;
    conclNpaCode?: string;
    conclBpTypeCode?: string;
    conclDescrCode?: string;
    conclGoalCode?: string;
    conclFrCode?: string;
    conclExpendCode?: string;
    conclDescrInflCode?: string;
    conclDriCode?: string;
    conclCommonCode?: string;
    conclSeeCode?: string;
    conclPeeCode?: string;

    constructor(
        planYear: number,
        region: string,
        abp: number,
        prg: number,
        ppr: number | null
    ) {
        this.planYear = planYear;
        this.region = region;
        this.abp = abp;
        this.prg = prg;
        this.ppr = ppr;
    }

    public static clone(source: Brbp): Brbp {
        const res = new Brbp(
            source.planYear,
            source.region,
            source.abp!,
            source.prg!,
            source.ppr
        );
        res.id = source.id;
        res.typeBpLevel = source.typeBpLevel;
        res.typeBpContent = source.typeBpContent;
        res.typeBpImpl = source.typeBpImpl;
        res.resultOther = source.resultOther;
        res.descrRu = source.descrRu;
        res.descrKk = source.descrKk;
        res.goalOtherRu = source.goalOtherRu;
        res.goalOtherKk = source.goalOtherKk;
        res.goal = source.goal;
        res.driByRegion = source.driByRegion;
        res.driByProject = source.driByProject;
        res.textGoalFr = source.textGoalFr;
        res.conclNpaRu = source.conclNpaRu;
        res.conclBpTypeRu = source.conclBpTypeRu;
        res.conclDescrRu = source.conclDescrRu;
        res.conclGoalRu = source.conclGoalRu;
        res.conclFrRu = source.conclFrRu;
        res.conclExpendRu = source.conclExpendRu;
        res.conclDescrInflRu = source.conclDescrInflRu;
        res.conclDriRu = source.conclDriRu;
        res.conclCommonRu = source.conclCommonRu;
        res.conclSeeRu = source.conclSeeRu;
        res.conclPeeRu = source.conclPeeRu;
        res.conclNpaCode = source.conclNpaCode;
        res.conclBpTypeCode = source.conclBpTypeCode;
        res.conclDescrCode = source.conclDescrCode;
        res.conclGoalCode = source.conclGoalCode;
        res.conclFrCode = source.conclFrCode;
        res.conclExpendCode = source.conclExpendCode;
        res.conclDescrInflCode = source.conclDescrInflCode;
        res.conclDriCode = source.conclDriCode;
        res.conclCommonCode = source.conclCommonCode;
        res.conclSeeCode = source.conclSeeCode;
        res.conclPeeCode = source.conclPeeCode;
        return res;
    }
}