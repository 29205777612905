/**
 * kz.csi.stat.csc.modules.budget.request.bp.data.BudgetRequestBpSeeDto
 */
export class BrbpPee {
    id?: number;
    budgetRequestBp?: number;
    region?: string;
    bip?: string;
    project?: number | any;
    expendReportYear?: number;
    expendCorrectPlan?: number;
    expendPlanPeriod1?: number;
    expendPlanPeriod2?: number;
    expendPlanPeriod3?: number;
    noteRu?: string;

    public static clone(source: BrbpPee): BrbpPee {
        const res = new BrbpPee();
        res.id = source.id;
        res.budgetRequestBp = source.budgetRequestBp;
        res.region = source.region;
        res.bip = source.bip;
        res.project = source.project;
        res.expendReportYear = source.expendReportYear;
        res.expendCorrectPlan = source.expendCorrectPlan;
        res.expendPlanPeriod1 = source.expendPlanPeriod1;
        res.expendPlanPeriod2 = source.expendPlanPeriod2;
        res.expendPlanPeriod3 = source.expendPlanPeriod3;
        res.noteRu = source.noteRu;
        return res;
    }
}