/**
 * kz.csi.stat.csc.modules.budget.request.bp.data.BudgetRequestBpDriDto
 */
export class BrbpDri {
    id?: number;
    budgetRequestBp?: number;
    region?: string;
    bip?: string;
    project?: number | any;
    indicator?: number | any;
    finalResult?: string;
    reportYear?: number;
    correctPlan?: number;
    planPeriod1?: number;
    planPeriod2?: number;
    planPeriod3?: number;
    noteRu?: string;

    public static clone(source: BrbpDri): BrbpDri {
        const res = new BrbpDri();
        res.id = source.id;
        res.budgetRequestBp = source.budgetRequestBp;
        res.region = source.region;
        res.bip = source.bip;
        res.project = source.project;
        res.indicator = source.indicator;
        res.finalResult = source.finalResult;
        res.reportYear = source.reportYear;
        res.correctPlan = source.correctPlan;
        res.planPeriod1 = source.planPeriod1;
        res.planPeriod2 = source.planPeriod2;
        res.planPeriod3 = source.planPeriod3;
        res.noteRu = source.noteRu;
        return res;
    }
}