import {Brbp} from "@/modules/budget-request/components/js/brbp";
import {BrbpDri} from "@/modules/budget-request/components/js/dri";
import {BrbpSee} from "@/modules/budget-request/components/js/see";
import {BrbpPee} from "@/modules/budget-request/components/js/pee";

export interface IBrbpWrapper {
    brbp?: Brbp;
    driData: BrbpDri[];
    seeData: BrbpSee[];
    peeData: BrbpPee[];
}

/**
 * kz.csi.stat.csc.modules.budget.request.bp.pojo.BrbpPprWrapper
 */
export class BrbpPprWrapper implements IBrbpWrapper {
    brbp?: Brbp;
    driData: BrbpDri[];
    seeData: BrbpSee[];
    peeData: BrbpPee[];

    constructor(brbpPpr: Brbp | undefined, driData: BrbpDri[], seeData: BrbpSee[], peeData: BrbpPee[]) {
        this.brbp = brbpPpr;
        this.driData = driData;
        this.seeData = seeData;
        this.peeData = peeData;
    }

    public static clone(source: BrbpPprWrapper): BrbpPprWrapper {
        return new BrbpPprWrapper(
            source.brbp ? Brbp.clone(source.brbp) : undefined,
            source.driData.map(d => BrbpDri.clone(d)),
            source.seeData.map(d => BrbpSee.clone(d)),
            source.peeData.map(d => BrbpPee.clone(d))
        );
    }
}

/**
 * kz.csi.stat.csc.modules.budget.request.bp.pojo.BrbpWrapper
 * class BrbpWrapper (

 var brbpPrg: BudgetRequestBpRec?,

 var pprData: List<BrbpPprWrapper>?

 )
 */
export class BrbpWrapper implements IBrbpWrapper {
    brbp?: Brbp;
    pprData: BrbpPprWrapper[] = [];
    driData: BrbpDri[] = [];
    seeData: BrbpSee[] = [];
    peeData: BrbpPee[] = [];
    public static clone(source: BrbpWrapper): BrbpWrapper {
        const res = new BrbpWrapper();
        if (source.brbp) {
            res.brbp = Brbp.clone(source.brbp);
        }
        source.pprData.forEach(d => res.pprData.push(BrbpPprWrapper.clone(d)));
        source.driData.forEach(d => res.driData.push(BrbpDri.clone(d)));
        // source.seeData.forEach(d => res.seeData.push(BrbpSee.clone(d)));
        return res;
    }
}