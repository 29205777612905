





































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CActionModal extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private aggHistory!: any[];
    @Prop({
        required: true,
        default: []
    })
    private agrCodesDict!: any[];
    @Prop({
        required: true,
    })
    private aggrLevelCode!: number;
    @Prop({
        required: true,
    })
    private brbpCode!: number;
    @Prop({
        required: true,
    })
    private saveConclStep!: Function;
    @Prop({
        required: true,
    })
    private getCurrStep!: string;
    @Prop({
        required: true,
    })
    private fieldLocalName!: any;
    @Prop({
        required: true,
    })
    private disableConclBtns!: Boolean;
    @Prop({
        required: true,
    })
    private canMoveForward!: Boolean;
    @Prop({
        required: true,
    })
    private dataChanged!: Boolean;
    @Prop({
        required: true,
    })
    private bpMode!: Boolean;
    @Prop({
        required: true,
        default: []
    })
    private errors!: any[];
    
    private commentText: string | null = null;

    mounted() {
        this.$root.$on('bv::modal::shown', () => {
            const elem = this.$refs.actionBlock as HTMLElement;
            if (elem && elem.lastElementChild) elem.lastElementChild.scrollIntoView()
        })
    }

    // получаем от какого шага к которому был переход
    private getCurrAction(item: any, idx: number) {
        let curr_step = 1;
        if (idx > 0) {
            curr_step = parseInt(this.aggHistory[idx - 1].approveStatus);
        } 
        const next_step = parseInt(item.approveStatus);
        const curr_step_obj = this.agrCodesDict.filter(curr => curr.code === curr_step)
        const next_step_obj = this.agrCodesDict.filter(next => next.code === next_step)

        let text = '';
        if (curr_step_obj.length) {
            text = curr_step_obj[0][this.fieldLocalName]
        } 
        if (next_step_obj.length) {
            text += ` -> ${next_step_obj[0][this.fieldLocalName]}`
        }
        return text
        
    }
    
 
    

    // получение локализованного имени поля
    private get fieldLocalName1() {
        return this.$i18n.locale === 'kk' ? 'btn_name_kk' : 'btn_name_ru';
    }

    // получаем цвет для перехода
    private labelColor(item: any, idx: number) {
        if (idx === 0) return 'forward';
        const prev_step = parseInt(this.aggHistory[idx - 1].approveStatus); // куда перешел с предыдущего шага
        const prevConclDictItem = this.agrCodesDict.filter((concl: any) => concl.code === prev_step)[0]
        if (prevConclDictItem && prevConclDictItem.next_step === parseInt(item.approveStatus)) return 'forward';
        if (parseInt(item.approveStatus) === 1) return 'reset';
        return null

    }

    // Объект текущего шага
    private get currStep() {
       if (!this.aggrLevelCode || !this.agrCodesDict.length) return null;
       const curr = this.agrCodesDict.filter((item: any) => item.code === this.aggrLevelCode)
       if (curr.length) return curr[0];
       return null;
    }

    //обработка кликов по кнопке 
    private requestText = ''; 
    private actionFunc: any ;
    private dasableNextBtn = false;
    private action: string = '';
    private btnClickHandler(action: string) {
        if (this.dataChanged) {
            this.dasableNextBtn = true;
            if (this.$i18n.locale === 'kk') {
                this.requestText = `Необходимо сохранить изменения, перед тем как ${this.forwardBtnName.toLowerCase()} проект.`;
            } else {
                this.requestText = `Необходимо сохранить изменения, перед тем как ${this.forwardBtnName.toLowerCase()} проект.`;
            }
        } else if (!this.canMoveForward && action === 'forward' && this.bpMode) {
            this.dasableNextBtn = true;
            if (this.$i18n.locale === 'kk') {
                this.requestText = `Есть разделы с отрицательным или не определенным решением. Вы не сможете ${this.forwardBtnName.toLowerCase()} проект.`;
            } else {
                this.requestText = `Есть разделы с отрицательным или не определенным решением. Вы не сможете ${this.forwardBtnName.toLowerCase()} проект.`;
            }
        } else {
            this.dasableNextBtn = false;
            switch (action) {
                case 'forward': 
                    if (this.$i18n.locale === 'kk') {
                        this.requestText = `Жобаны ${this.forwardBtnName.toLowerCase()}-ге сенімдісіз бе?`;
                    } else {
                        this.requestText = `Вы уверены что хотите ${this.forwardBtnName.toLowerCase()} проект?`;
                    }
                    break;
                case 'backword': 
                    if (this.$i18n.locale === 'kk') {
                        this.requestText = `Жобаны ${this.backwardBtnName.toLowerCase()}-ге сенімдісіз бе?`;
                    } else {
                        this.requestText = `Вы уверены что хотите ${this.backwardBtnName.toLowerCase()} проект?`;
                    }
                    break;
                default: ''
            }
        }
        
        this.action = action;
        this.$bvModal.show('action-modal-button');
    }

    // добавление записи в историю изменения статусов
    private addComment() {
        if (!this.brbpCode) return;
        const newObj = {
            budgetRequestBp: this.brbpCode,
            noteRu: this.commentText
        };
         
        const curStepObj = this.agrCodesDict.find(itm => itm.code === this.aggrLevelCode)
        switch (this.action) {
            case 'forward':
                if (curStepObj) {
                    this.$set(newObj, 'approveStatus', curStepObj.next_step)
                }
                break;
            case 'backword': 
                if (curStepObj) {
                    this.$set(newObj, 'approveStatus', curStepObj.prev_step)
                }
                break;
            case 'reset': 
                this.$set(newObj, 'approveStatus', "1")
                break;
            default: ''
        }
        this.saveConclStep(newObj)
        // this.aggHistory.unshift(newObj)
        this.commentText = ''
        this.$bvModal.hide('slot-modal');
    }

    //получаем названия кнопок
    private get backwardBtnName() {
        if (!this.currStep || !this.currStep.prev_step || !this.agrCodesDict.length) return null;
        const prevStep = this.agrCodesDict.filter((item: any) => item.code === this.currStep.prev_step)
        if (prevStep.length) {
            return prevStep[0][this.fieldLocalName1]
        }
        return null
    }

    private get forwardBtnName() {
        if (!this.currStep || !this.currStep.next_step || !this.agrCodesDict.length) return null;
        const nextStep = this.agrCodesDict.filter((item: any) => item.code === this.currStep.next_step)
        if (nextStep.length) {
            return nextStep[0][this.fieldLocalName1]
        }
        return null
    }
    // деактивация кнопок на первом и последнем шаге
    private get showBackward() {
        if (!this.currStep) return true;
        return Boolean(this.currStep.prev_step);
    }

    private get disableForward() {
        if (!this.currStep) return true;
        return !Boolean(this.currStep.next_step);
    }
}
