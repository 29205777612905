<template>
    <div class="items-list">
        <h6 class="items-list__title">Похожие наименования:</h6>
        <ul class="items-list__ul">
            <template v-for="(row, i) in itemsList">
                <li class="items-list__ul-li" :key="i">{{row}}</li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'HintList',
    props: {
        itemsList: Array
    }
};
</script>

<style>

    .items-list {
        margin-top: -1rem;
    }
    .items-list__title{
        font-size: .6em;
        color: #ADADAD;
        font-style: italic;
    }
    .items-list__ul {
        
    }
    .items-list__ul-li {
        /* list-style-type: none; */
        font-size: .6em;
        color: #ADADAD;
        font-style: italic;
    }
</style>