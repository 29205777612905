<template>
    <div class="filter-breadcrumb">
        <span class="item-breadcrumb" v-if="curRegion" @click="openFilterByRef('curRegionRef')">
            {{ curRegion.code }}-РЕГИОН
        </span>
        <span class="item-breadcrumb" v-if="curYear" @click="openFilterByRef('curYearRef')">
            {{ curYear.name}}
        </span>
        <span class="item-breadcrumb" v-if="curAbp" @click="openFilterByRef('abpRef')">
            {{curAbp.abp}}-АБП
        </span>
        <span class="item-breadcrumb" v-if="curPrgId && !main">
            {{curPrgId}}-БП
        </span>
    </div>
</template>
<script>
export default {
    name: 'CBreadCrumbs',
    props: {
        curRegion: Object,
        curYear: Object,
        curAbp: Object,
        curPrgId: {
            default: null,
            type: Number
        },
        main: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        openFilterByRef(refName) {
            this.$emit('openFilterByRef', refName);
        }
    }
};

</script>
<style scoped>

</style>
